<template>
  <v-container>
    <v-form v-model="valid">
      <v-row class="mb-4">
        <v-col cols="12" class="mb-4">
          <h5 class="subtitle-2 mb-3">{{ $t(briefAssessment[0].q) }}</h5>
          <v-layout>
            <v-btn
              v-for="a in questionAnswerData"
              :key="a.value"
              elevation="0"
              :outlined="a.value !== briefAssessment[0].a"
              color="accent"
              class="me-5"
              :ripple="false"
              @click="briefAssessment[0].a = a.value"
              >{{ $t(a.name) }}</v-btn
            >
          </v-layout>
        </v-col>
        <v-col cols="12" v-if="show2Q" class="mb-4">
          <h5 class="subtitle-2 mb-3">{{ $t(briefAssessment[1].q) }}</h5>
          <v-layout>
            <v-btn
              v-for="a in questionAnswerData"
              :key="a.value"
              elevation="0"
              :outlined="a.value !== briefAssessment[1].a"
              color="accent"
              class="me-5"
              :ripple="false"
              @click="briefAssessment[1].a = a.value"
              >{{ $t(a.name) }}</v-btn
            >
          </v-layout>
        </v-col>
        <v-col cols="12" v-if="show3Q">
          <h5 class="subtitle-2 mb-3">{{ $t(briefAssessment[2].q) }}</h5>
          <v-slider
            color="accent"
            track-color="accent"
            v-model="briefAssessment[2].a"
            :tick-labels="ticksLabels"
            :max="10"
            step="1"
            ticks="always"
            tick-size="4"
          ></v-slider>
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <CrisisManagement ref="crisisManagementRef" />
        <!-- <v-col
                    v-if="briefAssessment.questionThreeAnswer && briefAssessment.questionThreeAnswer <= 2 && briefAssessment.questionOneAnswer === 'no'"
                >0-2</v-col>-->
        <v-col cols="12" v-if="show4Q">
          <h5 class="subtitle-2 mb-3">{{ $t(briefAssessment[3].q) }}</h5>
          <v-btn
            v-for="a in questionAnswerData"
            :key="a.value"
            elevation="0"
            :outlined="a.value !== briefAssessment[3].a"
            color="accent"
            class="me-5"
            :ripple="false"
            @click="briefAssessment[3].a = a.value"
            >{{ $t(a.name) }}</v-btn
          >
        </v-col>
        <!-- <v-col
                    v-if="briefAssessment.questionThreeAnswer && briefAssessment.questionThreeAnswer > 5 && briefAssessment.questionOneAnswer === 'no'"
                >6-10</v-col>-->
      </v-row>
      <v-row class="mb-4">
        <v-col cols="12" v-if="show5Q">
          <h5 class="subtitle-2 mb-3">{{ $t(briefAssessment[4].q) }}</h5>
          <v-layout>
            <v-btn
              v-for="a in questionAnswerData"
              :key="a.value"
              elevation="0"
              :outlined="a.value !== briefAssessment[4].a"
              color="accent"
              class="me-5"
              :ripple="false"
              @click="briefAssessment[4].a = a.value"
              >{{ $t(a.name) }}</v-btn
            >
          </v-layout>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-btn x-large block color="primary" @click="prevStep">{{
            $t("Return")
          }}</v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            x-large
            block
            color="primary"
            :disabled="!nextDisabled"
            @click="preNextStep"
            >{{ $t("Next") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import CrisisManagement from "../../../components/InitialAssessment/CrisisManagement.vue";

const defaultBriefAssessment = () => {
  return [
    {
      q: "Some people who feel distressed sometimes report that they think about harming themselves or taking their own life. Have you ever felt like this?",
      a: null,
    },
    {
      q: "Have you ever attempted anything like that in the past?",
      a: null,
    },
    {
      q: "How likely are you to act on these thoughts right now? (0 not at all, 10 will definitely act on them straight away)",
      a: null,
    },
    {
      q: "Have you made any specific plans in relation to those thoughts?",
      a: null,
    },
    {
      q: "Have you taken any action towards carrying out these plans?",
      a: null,
    },
  ];
};

export default {
  name: "SecondStep",
  components: { CrisisManagement },
  props: {
    userRegistrationInfo: {
      required: true,
    },
  },
  data: () => {
    return {
      CrisisManagement: null,
      questionAnswerData: [
        {
          value: "No",
          name: "No",
        },
        {
          value: "Yes",
          name: "Yes",
        },
      ],
      briefAssessment: defaultBriefAssessment(),

      valid: false,
      ticksLabels: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    };
  },

  computed: {
    show2Q() {
      return this.briefAssessment[0].a === "Yes";
    },
    show3Q() {
      return !!this.briefAssessment[1].a;
    },
    show4Q() {
      return this.briefAssessment[2].a > 2 && this.briefAssessment[2].a < 6;
    },
    show5Q() {
      return this.briefAssessment[3].a === "Yes";
    },

    nextDisabled() {
      return (
        this.briefAssessment[0].a === "No" ||
        (this.briefAssessment[0].a === "Yes" && this.show3Q && !this.show4Q) ||
        this.briefAssessment[3].a === "No" ||
        this.briefAssessment[4].a
      );
    },
  },

  watch: {
    briefAssessment: {
      deep: true,
      handler() {
        if (this.briefAssessment[0].a === "No" && this.briefAssessment[1].a) {
          this.briefAssessment = defaultBriefAssessment();
          this.briefAssessment[0].a = "No";
        }
        if (!this.show4Q) {
          this.briefAssessment[3].a = null;
          this.briefAssessment[4].a = null;
        }
        if (this.briefAssessment[3].a === "No") {
          this.briefAssessment[4].a = null;
        }
      },
    },
  },

  methods: {
    preNextStep() {
      if (this.briefAssessment[0].a === "No") {
        this.nextStep();
        return;
      }
      if (
        this.briefAssessment[2].a < 6 &&
        this.briefAssessment[4].a !== "Yes"
      ) {
        this.$refs.crisisManagementRef.callback = this.nextStep;
        this.openCrisisManagementDialog();
      } else {
        this.$router.push({ name: "TerminateAccess" });
      }
    },

    nextStep() {
      let data = this.briefAssessment.filter((e) => e.a !== null);

      this.$emit("update:secondStep", data);
      this.$emit("update:step", 3);
    },

    prevStep() {
      this.$emit("update:step", 1);
    },

    openCrisisManagementDialog() {
      this.$refs.crisisManagementRef.dialog = true;
    },
  },
};
</script>
