<template>
  <v-container class="text-center container-spacing">
    <v-row>
      <v-col cols="12" sm="8" md="6" lg="4" xl="3" class="mx-auto">
        <img
          src="../../../assets/teenTherapyParent-1.svg"
          class="mb-10"
          alt=""
        />
        <p class="body-1">
          {{
            $t(
              "If you are under the age of 18, you will need a parent's permission to attend therapy. Please obtain parental or legal guardian consent to set up your account."
            )
          }}
        </p>
        <v-btn
          color="primary"
          block
          class="py-3 mt-10"
          :to="{ name: 'Types' }"
        >
          Go to Teen Therapy
          <v-icon right>{{ $vuetify.rtl ? 'mdi-arrow-right mdi-rotate-180'  :  'mdi-arrow-right '}}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "TeenTherapyNotChildParent",
};
</script>
