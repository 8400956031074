<template>
  <v-container class="container-spacing">
    <v-row>
      <v-col cols="12" md="8" class="mx-auto d-flex flex-column">
        <h6 class="title font-weight-semibold mb-lg-2 text-center">
          {{ $t("How would you like to access your benefits?") }}
        </h6>
        <!--        <div v-for="s in accessTypes" :key="s.name">-->
        <!--          <v-btn-->
        <!--            elevation="0"-->
        <!--            :outlined="!s.value"-->
        <!--            :color="!s.value ? 'muted' : 'primary'"-->
        <!--            block-->
        <!--            :ripple="false"-->
        <!--            @click="selectType(s)"-->
        <!--            class="me-5 pa-2 mt-md-3 mt-2"-->
        <!--          >-->
        <!--            <div-->
        <!--              class="d-flex justify-start text-start align-items-center w-100"-->
        <!--            >-->
        <!--              <CorporateCode v-if="s.type === 'Code'" />-->
        <!--              <Email v-if="s.type === 'Email'" />-->
        <!--              <div class="d-flex w-100">-->
        <!--                <div class="mx-3 d-flex flex-column align-start justify-center">-->
        <!--                  <span-->
        <!--                    class="d-block font-weight-bold"-->
        <!--                    :class="!s.value ? 'black&#45;&#45;text opacity-75' : 'white&#45;&#45;text'"-->
        <!--                  >-->
        <!--                    {{ $t(s.name) }}-->
        <!--                  </span>-->
        <!--                  <span-->
        <!--                    class="d-block"-->
        <!--                    :class="-->
        <!--                      !s.value-->
        <!--                        ? 'black&#45;&#45;text text&#45;&#45;lighten-5'-->
        <!--                        : 'white&#45;&#45;text text&#45;&#45;darken-1'-->
        <!--                    "-->
        <!--                    :style="-->
        <!--                      $vuetify.rtl ? 'font-size: 13px;' : 'font-size: 11px;'-->
        <!--                    "-->
        <!--                  >-->
        <!--                    {{ $t(s.subname) }}-->
        <!--                  </span>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </v-btn>-->
        <!--          <v-sheet color="#E5F5F6" class="rounded-bottom-15px additional-info" v-if="s.value">-->
        <!--            <v-form ref="form" class="d-flex flex-md-row flex-column">-->
        <!--                <v-text-field outlined solo flat dense class="rounded-15px" :label="s.type === 'Email' ? $t('Enter email here') : $t('Enter code here')"></v-text-field>-->
        <!--                <v-btn :block="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" color="primary" class="ms-md-5 align-self-start verify-button py-3 px-7">{{$t("Verify")}}</v-btn>-->
        <!--            </v-form>-->

        <!--          </v-sheet>-->
        <!--        </div>-->

        <!-- <v-btn
              color="primary"
              :disabled="!selectedServiceValue"
              class="px-5 py-1 mx-auto mt-5 mt-lg-7"
              @click="goNextStep"
            >
              {{ $t("Continue") }} <v-icon right>{{ $vuetify.rtl ? 'mdi-arrow-right mdi-rotate-180'  :  'mdi-arrow-right '}}</v-icon>
            </v-btn> -->

        <div>
          <v-btn
            elevation="0"
            :outlined="false"
            :color="openInsurance ? 'primary' : 'muted'"
            block
            :ripple="false"
            class="me-5 pa-2 mt-md-3 mt-2 corporate-type-button"
            :class="openInsurance ? 'opened' : ''"
            @click="openInsurance = !openInsurance"
          >
            <!--              @click="selectType(s)"-->
            <div
              class="d-flex justify-start text-start align-items-center w-100"
            >
              <CorporateCode />
              <div class="d-flex w-100">
                <div class="mx-3 d-flex flex-column align-start justify-center">
                  <span
                    class="d-block font-weight-bold white--text text--darken-1"
                  >
                    {{ $t("Policy Number") }}
                  </span>
                  <span
                    class="d-block white--text text--darken-1"
                    :style="
                      $vuetify.rtl ? 'font-size: 13px;' : 'font-size: 11px;'
                    "
                  >
                    {{$t('Check my benefits through my insurance policy number')}}
                  </span>
                </div>
              </div>
            </div>
          </v-btn>
          <v-sheet v-show="openInsurance" color="#E5F5F6" class="rounded-bottom-15px additional-info">
            <InsuranceForm @continueInsurance="continueInsurance" :insuranceData.sync="insuranceData"/>
          </v-sheet>
        </div>

        <div>
          <v-btn
            elevation="0"
            :outlined="false"

            :color="openCorporate ? 'primary' : 'muted'"
            block
            :ripple="false"
            class="me-5 pa-2 mt-md-3 mt-2 corporate-type-button"
            :class="openCorporate ? 'opened' : ''"
            @click="openCorporate = !openCorporate"
          >
            <!--              @click="selectType(s)"-->
            <div
              class="d-flex justify-start text-start align-items-center w-100"
            >
              <CorporateCode />
              <div class="d-flex w-100">
                <div class="mx-3 d-flex flex-column align-start justify-center">
                  <span
                    class="d-block font-weight-bold white--text text--darken-1"
                  >
                    {{ $t("Benefits Code") }}
                  </span>
                  <span
                    class="d-block white--text text--darken-1"
                    :style="
                      $vuetify.rtl ? 'font-size: 13px;' : 'font-size: 11px;'
                    "
                  >
                    {{ $t("I have a benefits code provided by my insurance or employer") }}
                  </span>
                </div>
              </div>
            </div>
          </v-btn>
          <v-sheet v-show='openCorporate' color="#E5F5F6" class="rounded-bottom-15px additional-info">
            <v-form ref="form">
              <div class="d-flex flex-md-row flex-column">
                <v-text-field
                  outlined
                  solo
                  flat
                  dense
                  v-model="promoCode"
                  class="rounded-15px"
                  :label="$t('Enter code here')"
                  :error-messages="codeNotValidMessages"
                  :success="codeValid"
                  :readonly="codeValid"
                  :loading="loading"
                />

                <v-btn
                  :block="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                  color="primary"
                  class="ms-md-5 align-self-start verify-button py-3 px-7"
                  :loading="loading"
                  :disabled="codeValid"
                  @click="verifyCode"
                >
                  {{ $t("Verify") }}
                </v-btn>
              </div>
              <div
                class="d-flex flex-wrap align-center font-size-12 justify-center mb-2"
              >
                <p class="mb-0">{{ $t("Don't have a code?") }}</p>
                <a
                  href="mailto:customercare@talktime.ae"
                  class="ms-1 text-decoration-none primary--text font-weight-bold"
                  >{{ $t("Contact us") }}</a
                >
              </div>
              <div
                class="d-flex flex-md-row flex-column mt-8 mt-md-0"
                v-if="requiredEmail"
              >
                <v-text-field
                  outlined
                  solo
                  flat
                  dense
                  class="rounded-15px"
                  :label="$t('Enter email here')"
                  :error-messages="emailNotValidMessages"
                  :success="emailValid"
                  :loading="loading"
                />

                <v-btn
                  :block="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                  color="primary"
                  class="ms-md-5 align-self-start verify-button py-3 px-7"
                  :loading="loading"
                  @click="verifyEmail"
                >
                  {{ $t("Verify") }}
                </v-btn>
              </div>
            </v-form>
          </v-sheet>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CorporateCode from "@/components/svg/CorporateCode";
import { mapActions, mapGetters, mapMutations } from "vuex";
import UserMixin from "@/mixins/UserMixin";
import InsuranceForm from "@/components/Admin/CorporateProgram/InsuranceForm.vue";
// import Email from "@/components/svg/Email";

export default {
  name: "AccessType",
  components: {
    InsuranceForm,
    CorporateCode,
    // Email,
  },
  mixins: [UserMixin],
  data: () => {
    return {
      openInsurance: false,
      openCorporate: false,
      loading: false,
      // selectedAccessType: "",

      // accessTypes: [
      // {
      //   name: "Corporate email",
      //   type: "Email",
      //   subname: "I will provide my company email to sign up",
      //   value: false,
      // },
      //   {
      //     name: "Corporate code",
      //     type: "Code",
      //     subname: "My employer has provided me with a code",
      //     value: false,
      //   },
      // ],

      promoCode: "",

      codeValid: false,
      codeNotValidMessages: [],

      insuranceData: {
        insurancePolicyNumber: '',
        firstName: '',
        lastName: '',
        birthDate: '',

      },

      requiredEmail: false,
      emailValid: false,
      emailNotValidMessages: [],
    };
  },

  computed: {
    ...mapGetters("therapist", ["demoTherapist"]),
  },



  methods: {
    ...mapActions("corporateProgram", ["checkPromotionCode"]),

    ...mapActions("user", ["addPromoCodeToUser"]),

    ...mapActions("therapist", ["addTherapistToUserCompany"]),

    ...mapMutations("preRegister", ["setPromoCodeData", 'setInsuranceData', 'setTherapyType']),

    async verifyCode() {
      this.loading = true;

      try {
        let res = await this.checkPromotionCode({ code: this.promoCode });

        this.requiredEmail = res.requiredEmail;
        this.codeNotValidMessages = [];
        this.codeValid = true;

        this.setPromoCodeData(res);

        if (!this.requiredEmail) {
          await this.goNextStep();
        }
      } catch (e) {
        console.error("verifyCode: ", e);
        // TODO: Sahak translate
        this.codeNotValidMessages = ["Code Not Valid"];
      }

      this.loading = false;

      // setTimeout(() => {
      //   this.loading = false;
      //   this.requiredEmail = true;
      //   this.codeValid = true;
      // }, 1000)
    },

    continueInsurance() {
      this.setInsuranceData(this.insuranceData)
      this.goNextStep();
    },

    verifyEmail() {
      this.loading = true;

      setTimeout(() => {
        this.loading = false;
        this.requiredEmail = true;
        this.codeValid = true;

        this.goNextStep();
      }, 1000);
    },

    // selectType(type) {
    //   if (!type.value) {
    //     this.accessTypes.forEach((i) => {
    //       i.value = false;
    //     });
    //     type.value = true;
    //
    //     type.value
    //       ? (this.selectedAccessType = type.type)
    //       : (this.selectedAccessType = "");
    //
    //     // this.setTherapyType(this.selectedAccessType);
    //   }
    // },

    async goNextStep() {
      if (this.$route.name === "useCorporateCoupon") {
        if (!this.demoTherapist) {
          await this.$router.replace("/");
        } else {
          await this.addPromoCodeToUser({ promoCode: this.promoCode });
          this.loading = true;
          this.addTherapistToUserCompany({
            id: this.demoTherapist._id,
          }).then(() => {
            this.$nextTick(async () => {
              this.loading = false;
              await this.getMe();
              await this.$router.replace({ name: "Calendar" });
            });
          });
        }
      } else {
        this.setTherapyType("Individual Therapy");

        await this.$router.push({
          name: "PrimaryReasons",
        });
      }
    },
  },
};
</script>

<style scoped>
::v-deep.v-btn {
  height: auto !important;
  z-index: 1;
}

::v-deep.v-btn:not(.verify-button) .v-btn__content {
  white-space: normal;
  flex-wrap: wrap;
  flex: auto;
  justify-content: start;
  padding: 10px;
}

::v-deep.v-btn.verify-button {
  max-height: 40px;
}

::v-deep.v-btn.v-btn--outlined:not(.v-btn--plain) {
  /* box-shadow: 0px 13px 21px rgba(52, 117, 178, 0.35); */
  border-radius: 15px !important;
}

::v-deep.v-btn--disabled {
  border-radius: 15px;
}

.rounded-bottom-15px {
  border-radius: 0 0 15px 15px;
}

.additional-info {
  padding: 40px 40px 10px 40px;
  z-index: -1;
  transform: translateY(-20px);
}
.corporate-type-button:not(.opened) .v-btn__content span {
  color: rgba(0,0,0,.6) !important;
}
</style>
