<template>
  <v-card class="rounded-card-with-shadow card-spacing">
    <!-- <v-toolbar color="primary" elevation="0" dark>
      <logoSvg/>
    </v-toolbar> -->
    <v-card-text class="pa-6">
      <h2 class="mb-4 black--text title font-weight-bold">
        {{ $t("PasswordReset") }}
      </h2>
      <p class="mb-5 body-2 grey--text text--darken-5">
        {{ $t("PasswordResetNewPassword") }}
      </p>

      <v-form
        v-if="showResetPasswordForm"
        ref="resetPasswordForm"
        v-model="valid"
        lazy-validation
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="email"
              :rules="[required, emailRule]"
              :label="$t('Email')"
              type="email"
              disabled
              readonly
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[required, min]"
              :type="show ? 'text' : 'password'"
              :label="$t('Password')"
              hint="At least 8 characters"
              counter
              autocomplete="on"
              @click:append="show = !show"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              block
              outlined
              v-model="verify"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[required, passwordMatch]"
              :type="show ? 'text' : 'password'"
              :label="$t('ConfirmPassword')"
              counter
              autocomplete="on"
              @click:append="show = !show"
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="pt-5 pb-4">
            <v-btn
              x-large
              block
              color="primary"
              class="px-13 py-3 text-none"
              :disabled="!valid"
              @click="resetPasswordAction"
            >
              {{ $t("ResetPassword") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <template v-else>
        <h3 class="error--text ma-5 pa-5" v-if="errorText">
          {{ errorText }}
        </h3>
        <v-skeleton-loader
          width="450"
          type="card-heading, list-item@3, actions"
        ></v-skeleton-loader>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
// import logoSvg from "./../../components/svg/Logo";

export default {
  name: "ResetPassword",
  // components: { logoSvg },

  data: () => {
    return {
      showResetPasswordForm: false,
      loading: false,
      errorText: false,

      valid: true,
      show: false,
      email: "",
      password: "",
      verify: "",
    };
  },
  computed: {
    passwordMatch() {
      return () =>
        this.password === this.verify || this.$t("Passwords must match");
    },
    emailRule() {
      return (v) => /.+@.+\..+/.test(v) || this.$t("E-mail must be valid");
    },
    required() {
      return (value) => !!value || this.$t("Required:");
    },
    min() {
      return (v) => (v && v.length >= 8) || this.$t("Min 8 characters");
    },
  },

  async mounted() {
    try {
      this.loading = true;

      await this.forgotPasswordVerify({
        verification: this.$route.params.token,
      })
        .then((res) => {
          this.email = res.email;
          this.showResetPasswordForm = true;
        })
        .catch((err) => {
          const data = err.response.data;
          if (data.verificationExpires) {
            this.errorText = data.message;
          }
          this.$router.replace({ name: "Login" });
        });
    } finally {
      this.loading = false;
    }
  },

  methods: {
    ...mapActions("user", ["forgotPasswordVerify", "forgotPasswordReset"]),

    resetPasswordAction() {
      if (this.$refs.resetPasswordForm.validate()) {
        this.forgotPasswordReset({
          email: this.email,
          password: this.password,
        }).then(() => {
          this.$router.push({ name: "Login" });
        });
      }
    },
  },
};
</script>

<style scoped></style>
