<template>
  <v-container>
    <v-form v-model="valid" ref="form">
      <v-row>
        <v-col cols="auto" class="pt-0">
          <h5 class="subtitle-1 font-weight-medium mb-0">
            {{ $t("YourName") }}
          </h5>
        </v-col>
        <v-col cols="auto" class="ms-sm-auto text-right pt-0">
          <v-switch
            hide-details
            inset
            dense
            :ripple="false"
            color="accent"
            class="reversed-label mt-0 py-1"
            v-model="userRegistrationInfo.profile.anonymous"
          >
            <template v-slot:label>
              <small class="me-5">{{ $t("RemainAnonymous") }}</small>
            </template>
          </v-switch>
        </v-col>
      </v-row>
      <v-row class="mb-4" v-if="!userRegistrationInfo.profile.anonymous">
        <v-col cols="12" md="6">
          <v-text-field
            key="firstname"
            outlined
            dense
            v-model="userRegistrationInfo.profile.firstname"
            :rules="nameRules"
            :label="$t('FirstName')"
            required
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            key="lastname"
            dense
            outlined
            v-model="userRegistrationInfo.profile.lastname"
            :rules="nameRules"
            :label="$t('LastName')"
            required
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mb-4" v-else>
        <v-col cols="12">
          <v-text-field
            key="username"
            dense
            outlined
            :rules="username"
            v-model="userRegistrationInfo.profile.username"
            :label="$t('Username')"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col cols="12" md="4">
          <h5 class="subtitle-1 font-weight-medium mb-3">
            {{ $t("DateOfBirth") }}
          </h5>
          <v-menu
            ref="birthDateMenuRef"
            v-model="birthDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                dense
                :rules="birthDateRules"
                v-model="userRegistrationInfo.profile.birthDate"
                label="DD/MM/YYYY"
                readonly
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :locale="$vuetify.rtl ? 'ar' : 'en'"
              no-title
              width="240"
              v-model="userRegistrationInfo.profile.birthDate"
              :show-current="false"
              :active-picker.sync="dateActivePicker"
              :max="maxBirthDate"
              min="1950-01-01"
              @change="saveBirthDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6" class="ms-auto">
          <h5 class="subtitle-1 font-weight-medium mb-3">
            {{ $t("WhatIsYourGender") }}
          </h5>
          <v-layout class="flex-wrap">
            <v-btn
              v-for="g in genderData"
              :key="g.value"
              elevation="0"
              :outlined="g.value !== userRegistrationInfo.profile.gender"
              color="accent"
              :block="$vuetify.breakpoint.xs ? true : false"
              class="me-5 py-sm-5 my-1 my-sm-0"
              :ripple="false"
              @click="userRegistrationInfo.profile.gender = g.value"
              >{{ g.name }}</v-btn
            >
          </v-layout>
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col class="mb-4" cols="12">
          <h5 class="subtitle-1 mb-3 font-weight-medium">
            {{ $t("WhereAreYouLocated") }}
          </h5>
          <v-select
            dense
            hide-details
            :rules="countryRules"
            v-model="userRegistrationInfo.profile.location.country"
            :items="countries.map((item) => item)"
            outlined
            :item-text="getCountryItemName"
            item-value="name"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <h5 class="subtitle-1 mb-3 font-weight-medium">
            {{ $t(briefAssessment[0].q) }}
          </h5>
          <v-radio-group
            hide-details
            v-model="briefAssessment[0].a"
            row
            :rules="required"
          >
            <v-radio
              v-for="(item, index) in briefAssessment[0].options"
              :key="index"
              :label="$t(item)"
              class="my-1"
              color="accent"
              :value="item"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row v-if="briefAssessment[0].a && briefAssessment[0].a !== 'No'">
        <v-col cols="12">
          <h5 class="subtitle-1 font-weight-medium">
            {{ $t(briefAssessment[1].q) }}
          </h5>
        </v-col>
        <v-col
          v-for="i in briefAssessment[1].a"
          :key="i.name"
          cols="12"
          md="6"
          class="py-1"
        >
          <v-checkbox
            v-model="i.value"
            :label="$t(i.name)"
            color="accent"
            class="mt-0"
            hide-details
          />
        </v-col>
        <v-col class="my-4" cols="12">
          <h5 class="subtitle-1 font-weight-medium">
            {{ $t(briefAssessment[2].q) }}
          </h5>
          <v-radio-group v-model="briefAssessment[2].a" row :rules="required">
            <v-radio :label="$t('No')" color="accent" value="no"></v-radio>
            <v-radio :label="$t('Yes')" color="accent" value="yes"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-btn x-large block color="primary" disabled @click="prevStep">{{
            $t("Return")
          }}</v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            x-large
            block
            color="primary"
            :disabled="!(valid && briefAssessmentValid)"
            @click="nextStep"
            >{{ $t("Next") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { countries } from "../../../constants/countries";
export default {
  name: "FirstStep",
  props: {
    userRegistrationInfo: {
      required: true,
    },
  },
  data: () => {
    return {
      counsellingBefore: null,
      counsellingHelpful: null,
      briefAssessment: [
        {
          q: "Have you been to therapy / counseling before?",
          a: "",
          options: ["No", "YesFewSessions", "YesForOnePlusYears"],
        },
        {
          q: "What type of therapy / Counseling did you have?",
          a: [
            {
              name: "Cognitive Behavioral Therapy (CBT)",
              value: false,
            },
            {
              name: "Dialectical Behavior Therapy (DBT)",
              value: false,
            },
            {
              name: "Psychodynamic Therapy",
              value: false,
            },
            {
              name: "Humanistic Therapy",
              value: false,
            },
            {
              name: "Supportive Therapy",
              value: false,
            },
            {
              name: "Other kinds of talking therapy",
              value: false,
            },
            {
              name: "I don’t know",
              value: false,
            },
          ],
        },
        {
          q: "Did you find your previous therapy / counseling helpful?",
          a: "",
        },
      ],
      userInfoFromIp: {},
      dateActivePicker: null,
      birthDateMenu: false,
      valid: false,
      countries,

      // Rules
      required: [(v) => !!v || "Required"],
      username: [
        (v) => !!v || "Username is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 10 || "Name must be less than 10 characters",
      ],
      birthDateRules: [(v) => !!v || "Birth Date is required"],
      countryRules: [(v) => !!v || "Location is required"],
    };
  },
  computed: {
    genderData() {
      return [
        {
          value: "male",
          name: this.$t("Male"),
        },
        {
          value: "female",
          name: this.$t("Female"),
        },
        {
          value: "preferNotToSay",
          name: this.$t("Prefer not to say"),
        },
      ];
    },

    maxBirthDate() {
      let today = new Date();
      today.setFullYear(today.getFullYear() - 18);
      return today.toISOString().substring(0, 10);
    },
    briefAssessmentValid() {
      return (
        this.briefAssessment[0].a === "No" ||
        (this.briefAssessment[0].a &&
          this.briefAssessment[0].a !== "No" &&
          this.briefAssessment[1].a.some((e) => e.value))
      );
    },
  },
  watch: {
    birthDateMenu(val) {
      val && setTimeout(() => (this.dateActivePicker = "YEAR"));
    },
  },
  methods: {
    saveBirthDate(date) {
      this.$refs.birthDateMenuRef.save(date);
    },
    nextStep() {
      if (this.$refs.form.validate()) {
        let briefAssessmentData = [];
        if (this.briefAssessment[0].a === "No") {
          briefAssessmentData.push({
            q: this.briefAssessment[0].q,
            a: this.briefAssessment[0].a,
          });
        } else {
          briefAssessmentData = this.briefAssessment.map((e) => {
            if (e.a) {
              if (Array.isArray(e.a)) {
                return {
                  q: e.q,
                  a: e.a.filter((item) => item.value),
                };
              }
              return {
                q: e.q,
                a: e.a,
              };
            }
          });
        }
        this.$emit("update:firstStep", briefAssessmentData);
        this.$emit("update:step", 2);
      }
    },
    prevStep() {
      this.$emit("update:step", 1);
    },
    async getUserInformationFromIp() {
      try {
        // this.userInfoFromIp = await fetch('http://ip-api.com/json/').then(res => res.json());
        // this.userRegistrationInfo.profile.location.country = this.countries.find(c => c.code === this.userInfoFromIp.countryCode)?.name || '';
        this.userInfoFromIp = await fetch("https://ipapi.co/json/").then(
          (res) => res.json()
        );
        this.userRegistrationInfo.profile.location.country =
          this.countries.find(
            (c) => c.code === this.userInfoFromIp.country_code
          )?.name || "";
      } catch (e) {
        console.error(e);
      }
    },

    getCountryItemName(country) {
      return this.$t(country.name);
    },
  },
  mounted() {
    this.getUserInformationFromIp();
  },
};
</script>

<style scoped lang="scss">
.reversed-label::v-deep .v-input__slot {
  flex-direction: row-reverse;
}

.reversed-label::v-deep .v-input__slot {
  flex-direction: row-reverse;
  justify-content: start;
}

label.v-label {
  font-size: 14px !important;
}

label.v-label {
  font-size: 14px !important;
}

.v-input--selection-controls::v-deep .v-input__slot > .v-label {
  flex: 0 0 auto !important;
}
</style>
