<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px" @close="close" persistent>
      <v-card class="rounded-lg" :loading="loading">
        <v-btn
          @click="close"
          elevation="2"
          icon
          class="close-icon pa-0"
          :disabled="loading"
          :loading="loading"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title
          class="d-flex justify-center flex-column align-center pt-8"
        >
          <h5 class="secondary--text text-center">
            {{ $t("Your feedback is important to us") }}
          </h5>
          <p>{{ $t("How helpful was this session for you?") }}</p>
        </v-card-title>
        <v-card-text class="px-8 pb-10 pt-0 d-flex justify-center">
          <v-rating
            v-model="feedbackData.rating"
            background-color="grey lighten-2"
            color="secondary"
            size="44"
            length="5"
            hover
            @input="nextStep"
          ></v-rating>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="clientSurveyDialog"
      max-width="900px"
      scrollable
      persistent
      @close="close"
    >
      <v-btn
        @click="clientSurveyDialog = false"
        elevation="2"
        icon
        class="close-icon pa-0"
        :disabled="loading"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card
        class="rounded-lg pa-10"
        style="overflow-y: auto !important"
        color="#CCF5F3"
        :loading="loading"
      >
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="survey-wrapper">
            <h3 class="text-center mb-4 mt-2">
              {{ $t("Your feedback is important to us") }}
            </h3>
            <p>
              {{
                $t(
                  "Taking the time to fill out this survey will greatly assist your therapist in knowing what is working well in counseling, and what, needs to be adjusted in the way your therapy is conducted to help you achieve your goals."
                )
              }}
            </p>
          </div>
          <div class="survey-wrapper mt-5">
            <h3 class="mb-4 mt-2">
              {{ $t("How far you had expected to Improve by now?") }}
            </h3>
            <v-row>
              <v-col class="d-flex align-end justify-end">
                <p class="mb-0">{{ $t("No improvement") }}</p>
              </v-col>
              <v-col cols="auto" class="d-flex align-end justify-center">
                <v-radio-group
                  :rules="[(v) => !!v || '']"
                  hide-details
                  v-model="feedbackData.improvements"
                  row
                >
                  <v-radio label="1" value="1"></v-radio>
                  <v-radio label="2" value="2"></v-radio>
                  <v-radio label="3" value="3"></v-radio>
                  <v-radio label="4" value="4"></v-radio>
                  <v-radio label="5" value="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col class="d-flex align-end">
                <p class="mb-0">{{ $t("Mostly resolved") }}</p>
              </v-col>
            </v-row>
          </div>
          <div class="survey-wrapper mt-5">
            <h3 class="mb-4 mt-2">
              {{
                $t(
                  "How much you have experienced any of your symptoms over the past week?"
                )
              }}
            </h3>
            <v-row>
              <v-col class="d-flex align-end justify-end">
                <p class="mb-0">{{ $t("No symptoms") }}</p>
              </v-col>
              <v-col cols="auto" class="d-flex align-end justify-center">
                <v-radio-group
                  :rules="[(v) => !!v || '']"
                  hide-details
                  v-model="feedbackData.symptoms"
                  row
                >
                  <v-radio label="1" value="1"></v-radio>
                  <v-radio label="2" value="2"></v-radio>
                  <v-radio label="3" value="3"></v-radio>
                  <v-radio label="4" value="4"></v-radio>
                  <v-radio label="5" value="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col class="d-flex align-end">
                <p class="mb-0">{{ $t("Severe symptoms") }}</p>
              </v-col>
            </v-row>
          </div>
          <div class="survey-wrapper mt-5">
            <h3 class="mb-4 mt-2">
              {{
                $t("I was able to express my feelings during today’s session")
              }}
            </h3>
            <v-row>
              <v-col class="d-flex align-end justify-end">
                <p class="mb-0">{{ $t("Strongly Disagree") }}</p>
              </v-col>
              <v-col cols="auto" class="d-flex align-end justify-center">
                <v-radio-group
                  :rules="[(v) => !!v || '']"
                  hide-details
                  v-model="feedbackData.feelings"
                  row
                >
                  <v-radio label="1" value="1"></v-radio>
                  <v-radio label="2" value="2"></v-radio>
                  <v-radio label="3" value="3"></v-radio>
                  <v-radio label="4" value="4"></v-radio>
                  <v-radio label="5" value="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col class="d-flex align-end">
                <p class="mb-0">{{ $t("Strongly Agree") }}</p>
              </v-col>
            </v-row>
          </div>
          <div class="survey-wrapper mt-5">
            <h3 class="mb-4 mt-2">
              {{
                $t("My therapist seemed warm, supportive, and understanding")
              }}
            </h3>
            <v-row>
              <v-col class="d-flex align-end justify-end">
                <p class="mb-0">{{ $t("Strongly Disagree") }}</p>
              </v-col>
              <v-col cols="auto" class="d-flex align-end justify-center">
                <v-radio-group
                  :rules="[(v) => !!v || '']"
                  hide-details
                  v-model="feedbackData.supportive"
                  row
                >
                  <v-radio label="1" value="1"></v-radio>
                  <v-radio label="2" value="2"></v-radio>
                  <v-radio label="3" value="3"></v-radio>
                  <v-radio label="4" value="4"></v-radio>
                  <v-radio label="5" value="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col class="d-flex align-end">
                <p class="mb-0">{{ $t("Strongly Agree") }}</p>
              </v-col>
            </v-row>
          </div>
          <div class="survey-wrapper mt-5">
            <h3 class="mb-4 mt-2">
              {{ $t("The approach my therapist used was helpful") }}
            </h3>
            <v-row>
              <v-col class="d-flex align-end justify-end">
                <p class="mb-0">{{ $t("Strongly Disagree") }}</p>
              </v-col>
              <v-col cols="auto" class="d-flex align-end justify-center">
                <v-radio-group
                  :rules="[(v) => !!v || '']"
                  hide-details
                  v-model="feedbackData.helpful"
                  row
                >
                  <v-radio label="1" value="1"></v-radio>
                  <v-radio label="2" value="2"></v-radio>
                  <v-radio label="3" value="3"></v-radio>
                  <v-radio label="4" value="4"></v-radio>
                  <v-radio label="5" value="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col class="d-flex align-end">
                <p class="mb-0">{{ $t("Strongly Agree") }}</p>
              </v-col>
            </v-row>
          </div>
          <div class="survey-wrapper mt-5">
            <h3 class="mb-4 mt-2">
              {{ $t("Overall, I was satisfied with today's session") }}
            </h3>
            <v-row>
              <v-col class="d-flex align-end justify-end">
                <p class="mb-0">{{ $t("Strongly Disagree") }}</p>
              </v-col>
              <v-col cols="auto" class="d-flex align-end justify-center">
                <v-radio-group
                  :rules="[(v) => !!v || '']"
                  hide-details
                  v-model="feedbackData.satisfied"
                  row
                >
                  <v-radio label="1" value="1"></v-radio>
                  <v-radio label="2" value="2"></v-radio>
                  <v-radio label="3" value="3"></v-radio>
                  <v-radio label="4" value="4"></v-radio>
                  <v-radio label="5" value="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col class="d-flex align-end">
                <p class="mb-0">{{ $t("Strongly Agree") }}</p>
              </v-col>
            </v-row>
          </div>
          <div class="survey-wrapper mt-5">
            <h3 class="mb-4 mt-2">
              {{ $t("Additional Feedback") }}
            </h3>
            <v-row>
              <v-col class="d-flex align-end justify-center">
                <v-textarea
                  v-model="feedbackData.feedback"
                  :label="$t('Tell us more about your experience')"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </div>
          <v-btn
            color="secondary"
            class="mt-5"
            :disabled="loading"
            :loading="loading"
            @click="sendRatting"
          >
            {{ $t("Submit Feedback") }}
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ClientSurvey",
  data: () => {
    return {
      valid: true,
      dialog: false,
      loading: false,
      clientSurveyDialog: false,

      surveyId: null,
      feedbackData: {
        rating: null,
        improvements: null,
        symptoms: null,
        feelings: null,
        supportive: null,
        helpful: null,
        satisfied: null,
        feedback: "",
      },
    };
  },

  methods: {
    ...mapActions("clientSurvey", ["makeDone", "tyrLater"]),

    async sendRatting() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;
        await this.makeDone({ id: this.surveyId, data: this.feedbackData });
      } else {
        // console.log("not valid");
      }
      this.resetData();
    },

    nextStep() {
      this.dialog = false;
      this.clientSurveyDialog = true;
    },

    prevStep() {
      this.dialog = true;
      this.clientSurveyDialog = false;
    },

    open(surveyId) {
      this.surveyId = surveyId;
      this.dialog = true;
    },

    close() {
      let id = this.surveyId;
      this.tyrLater({ id });
      this.resetData();
    },

    resetData() {
      this.valid = true;
      this.dialog = false;
      this.loading = false;
      this.clientSurveyDialog = false;
      this.surveyId = null;
      this.feedbackData = {
        rating: null,
        improvements: null,
        symptoms: null,
        feelings: null,
        supportive: null,
        helpful: null,
        satisfied: null,
        feedback: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.survey-wrapper {
  background-color: white;
  border: 1px solid #ccc;
  padding: 45px;
  border-radius: 12px;
  &:first-child {
    position: relative;
    margin-top: 10px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    &::before {
      content: "";
      background: #00cebe;
      position: absolute;
      top: 0;
      left: -1px;
      transform: translateY(-100%);
      width: calc(100% + 2px);
      height: 12px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
  }
}
.v-radio {
  display: flex;
  flex-direction: column-reverse;
}
::v-deep .v-input--selection-controls__input {
  margin-right: 0;
  margin-left: 0;
}
p {
  opacity: 0.75;
}
::v-deep .v-label {
  opacity: 0.75;
  margin-bottom: 20px;
}
</style>
