<template>
  <v-app-bar
    style="height: 65px"
    app
    clipped-left
    background="lightGrey"
    class="border-bottom"
    flat
  >
    <v-toolbar-title class="d-flex align-center">
      <router-link text :ripple="false" :to="{ name: 'Calendar' }">
        <logoSvg class="logo-bigger" />
      </router-link>

      {{ $store.getters.accessToken }}
      <v-btn
        v-if="adminToken && accessToken !== adminToken"
        class="ml-6"
        small
        color="primary"
        outlined
        @click="backToAdmin"
      >
        <v-icon small left>mdi-arrow-left</v-icon>
        Back To Admin
      </v-btn>

      <v-btn
        icon
        class="ms-6 pb-md-2 d-block d-lg-none"
        @click="toggleDrawer"
        :ripple="false"
        plain
      >
        <v-icon v-if="!drawer">mdi-menu</v-icon>
        <v-icon v-else>mdi-close</v-icon>
      </v-btn>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items class="d-none d-md-block h-auto">
      <v-layout class="align-center">
        <ChangeLanguage />

        <div>
          <v-menu offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-btn plain :ripple="false" v-bind="attrs" v-on="on">
                <UserAvatar class="me-2" :user="me" :size="36" />
                {{ getUserName(me) }} <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group color="primary">
                <template v-for="(item, i) in items">
                  <v-list-item
                    v-if="item.show"
                    v-bind="item.props || null"
                    v-on="item.on || null"
                    :key="item.name + i"
                  >
                    <v-list-item-icon class="me-3">
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </v-layout>
    </v-toolbar-items>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <!-- <v-icon  class="d-md-none" dark>mdi-account-circle</v-icon> -->
        <v-btn v-on="on" icon plain :ripple="false" class="d-md-none">
          <UserAvatar :user="me" :size="24" />
        </v-btn>
        <ChangeLanguage
          v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
        />
        <!-- <v-menu offset-y> -->
        <!-- <template v-slot:activator="{ attrs, on }">
            <v-btn v-bind="attrs" class="d-md-none" v-on="on" icon :ripple="false" plain>
              <v-icon>mdi-earth</v-icon>
            </v-btn>
          </template> -->

        <!-- <v-list dense>
            <v-list-item-group :value="lang">
              <v-list-item link value="en" @click="onChangeLang('en')">
                <v-list-item-title class="text-center">English</v-list-item-title>
              </v-list-item>
              <v-list-item link value="ar" @click="onChangeLang('ar')">
                <v-list-item-title class="text-right">عربى</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu> -->
        <!-- <v-switch :input-value="themeDark" @click="changeTheme" dense class="d-md-none" inset color="secondary" :ripple="false"
          hide-details></v-switch> -->
      </template>
      <v-list dense>
        <v-list-item>
          <v-list-item-icon class="me-2">
            <UserAvatar :user="me" :size="24" />
          </v-list-item-icon>
          <v-list-item-title>
            {{ getUserName(me) }}
          </v-list-item-title>
        </v-list-item>
        <v-divider class="my-2"></v-divider>
        <v-list-item-group color="primary">
          <template v-for="(item, i) in items">
            <v-list-item
              v-if="item.show"
              v-bind="item.props || null"
              v-on="item.on || null"
              :key="item.name + i"
            >
              <v-list-item-icon class="me-2">
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <v-dialog v-model="logoutModal" persistent width="700">
      <v-card class="rounded-lg">
        <v-btn
          @click="logoutModal = false"
          elevation="2"
          icon
          class="close-icon pa-0"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-card-text class="pa-8">
          <p class="text-center mb-0 mt-4">{{ $t("LogoutText") }}</p>
        </v-card-text>

        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn color="primary" class="px-8 me-3" @click="logout" large>{{
            $t("Yes")
          }}</v-btn>
          <v-btn
            color="primary"
            outlined
            class="px-8"
            @click="logoutModal = false"
            large
            >{{ $t("No") }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import logoSvg from "@/components/svg/Logo";
import ChangeLanguage from "@/components/layout/ChangeLanguage";
import UserAvatar from "@/components/UserAvatar";
import UserMixin from "@/mixins/UserMixin";
import { mapActions, mapGetters, mapMutations } from "vuex";
import TherapistMixin from "@/mixins/TherapistMixin";

export default {
  name: "Navbar",
  components: { logoSvg, UserAvatar, ChangeLanguage},
  props: {
    drawer: {
      required: true,
    },
  },

  mixins: [UserMixin, TherapistMixin],


  data() {
    return {
      rtl: false,
      logoutModal: false,
    };
  },

  computed: {
    ...mapGetters(["lang", "themeDark"]),

    currentTherapistChatRoomId() {
      if (this.isTherapist) {
        return this.me?.rooms[0]?._id;
      }

      return this.me?.rooms?.find(
        (r) => r.users[0]._id === this.myTherapist?._id
      )._id;
    },

    items() {
      return [
        {
          name: this.$t("Profile"),
          icon: "mdi-account",
          show: true,
          props: {
            to: { name: "Profile" },
          },
        },
        {
          name: this.$t("Clients"),
          icon: "mdi-account-multiple",
          show: this.isTherapist,
          props: {
            to: { name: "Patients" },
          },
        },
        {
          name: this.$t("MyPlan"),
          icon: "mdi-book-check",
          show: this.isClient,
          props: {
            to: { name: "MyPlan" },
          },
        },
        {
          name: this.$t("MyTherapist"),
          icon: "mdi-shield-account",
          show: this.isClient,
          props: {
            to: { name: "MyTherapist" },
          },
        },
        {
          name: this.$t("Logout"),
          icon: "mdi-logout",
          show: true,
          on: {
            click: this.showLogoutModal,
          },
        },
      ];
    },
  },

  methods: {
    ...mapActions("user", ["logout"]),
    ...mapMutations(["changeLang", "changeThemeDark"]),
    ...mapMutations("user", ["setAccessToken"]),

    backToAdmin() {
      this.setAccessToken(this.adminToken);
      location.replace("/admin");
    },

    showLogoutModal() {
      this.logoutModal = true;
    },

    menuItems() {
      return this.menu;
    },

    changeTheme() {
      this.changeThemeDark(!this.themeDark);
    },

    onChangeLang(lang) {
      this.changeLang(lang);
    },

    toggleDrawer() {
      this.$emit("update:drawer", !this.drawer);
    },
  },
};
</script>

<style scoped lang="scss">
.v-icon::v-deep {
  opacity: 0.75;
}
</style>
