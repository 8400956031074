<template>
  <v-card class="rounded-card-with-shadow card-spacing">
    <v-card-text class="pa-6">
      <h2 class="mb-2 black--text title font-weight-bold">
        {{ $t("SignUp") }}
      </h2>
      <p class="mb-5 body-2 grey--text text--darken-5">
        {{ $t("SignUpTitle") }}
      </p>
      <v-form ref="registerForm" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="auto" class="ms-sm-auto text-right pa-sm-0">
            <v-switch
              hide-details
              inset
              dense
              :ripple="false"
              color="primary"
              class="reversed-label mt-0 py-1"
              v-model="anonymous"
            >
              <template v-slot:label>
                <small class="me-5 font-weight-bold">{{
                  $t("RemainAnonymous")
                }}</small>
              </template>
            </v-switch>
          </v-col>
          <v-col cols="12" class="pa-0"> </v-col>
          <v-col cols="12" md="6" class="py-0" v-if="!anonymous">
            <h5 class="black--text mb-1 opacity-75">First Name</h5>
            <v-text-field
              key="firstname"
              outlined
              solo
              dense
              flat
              v-model="firstName"
              :label="$t('FirstName')"
              required
            >
              <!-- :rules="nameRules" -->
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="py-0" v-if="!anonymous">
            <h5 class="black--text mb-1 opacity-75">Last Name</h5>
            <v-text-field
              key="lastname"
              solo
              flat
              dense
              outlined
              v-model="lastName"
              :label="$t('LastName')"
              required
            >
              <!-- :rules="nameRules" -->
            </v-text-field>
          </v-col>
          <v-col cols="12" class="py-0" v-else>
            <h5 class="black--text mb-1 opacity-75">{{ $t("Username") }}</h5>
            <v-text-field
              key="username"
              solo
              flat
              dense
              outlined
              v-model="username"
              :label="$t('Username')"
              required
            ></v-text-field>
            <!-- :rules="username" -->
          </v-col>
          <v-col cols="12" class="py-0">
            <h5 class="black--text mb-1 opacity-75">{{ $t("Email") }}</h5>
            <v-text-field
              solo
              flat
              outlined
              dense
              :hint="sponserType === 'myCompany' ? 'If you are using a benefits code, kindly signup with your corporate email' : ''"
              v-model="email"
              :rules="[required, emailRule]"
              :label="$t('Email')"
              type="email"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <h5 class="black--text mb-1 opacity-75">{{ $t("Password") }}</h5>
            <v-text-field
              solo
              flat
              outlined
              dense
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[required, min]"
              :type="show ? 'text' : 'password'"
              :label="$t('Password')"
              :hint="$t('At least 8 Characters')"
              counter
              autocomplete="on"
              @click:append="show = !show"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <h5 class="black--text mb-1 opacity-75">
              {{ $t("ConfirmPassword") }}
            </h5>
            <v-text-field
              solo
              flat
              outlined
              block
              dense
              v-model="verify"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[required, passwordMatch]"
              :type="show ? 'text' : 'password'"
              :label="$t('ConfirmPassword')"
              counter
              autocomplete="on"
              @click:append="show = !show"
            ></v-text-field>
          </v-col>
          <!--TODO: Remove v-if="false" after integration-->
<!--          <v-col cols="12" class="py-0">-->
<!--            <h5 class="black&#45;&#45;text mb-1 opacity-75">-->
<!--              {{$t("How did you find TalkTime?")}}-->
<!--            </h5>-->
<!--            <v-select-->
<!--              key="howDidYouFindUs"-->
<!--              outlined-->
<!--              :items="howDidYouFindUsItems"-->
<!--              solo-->
<!--              :rules="[required]"-->
<!--              dense-->
<!--              flat-->
<!--              item-text="name"-->
<!--              item-value="value"-->
<!--              v-model="howDidYouFindUs"-->
<!--              :label="$t('How did you find TalkTime?')"-->
<!--              required-->
<!--            >-->
<!--            </v-select>-->
<!--            <v-textarea-->
<!--              v-if="howDidYouFindUs === 'other'"-->
<!--              v-model="howDidYouFindUsOther"-->
<!--              outlined-->
<!--              solo-->
<!--              dense-->
<!--              flat-->
<!--              required-->
<!--              :rules="[required]"-->
<!--            >-->
<!--            </v-textarea>-->
<!--          </v-col>-->
          <v-col cols="12" class="py-0 mb-2">
            <h5 class="black--text text-center text-decoration-none">
              {{ $t("Already have an account?") }}
              <router-link
                :to="{ name: 'Login' }"
                class="primary--text text--darken-5"
                >{{ $t("Sign in") }}</router-link
              >
            </h5>
          </v-col>

          <v-col cols="12" class="mb-2">
            <v-btn
              block
              color="primary"
              class="px-13 py-3 text-none"
              :disabled="!valid"
              @click="registerAction"
              >{{ $t("Register") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "Register",

  data: () => {
    return {
      anonymous: true,
      username: "",
      lastName: "",
      firstName: "",

      email: "",

      password: "",
      verify: "",

      howDidYouFindUsOther: "",

      howDidYouFindUs: "",

      valid: true,
      show: false,
      showResendVerificationButton: false,
    };
  },

  computed: {
    ...mapState("preRegister", [
      "therapyType",
      "userDisorder",
      "clientPrefer",
      "sponserType",
      "promoCodeData",
      "insuranceData"
    ]),
    howDidYouFindUsItems() {
      return [
        { name: this.$t("Google"), value: "google" },
        { name: this.$t("Instagram"), value: "instagram" },
        { name: this.$t("Facebook"), value: "facebook" },
        { name: this.$t("TikTok"), value: "tiktok" },
        { name: this.$t("LinkedIn"), value: "linkedin" },
        {
          name: this.$t("Friend or family recommendation"),
          value: "friendOrFamily",
        },
        {
          name: this.$t("Healthcare professional recommendation"),
          value: "healthcareProfessionalRecommendation",
        },
        { name: this.$t("Other (please specify)"), value: "other" },
      ]
    },
    passwordMatch() {
      return () =>
        this.password === this.verify || this.$t("Passwords must match");
    },
    emailRule() {
      return (v) => /.+@.+\..+/.test(v) || this.$t("E-mail must be valid");
    },
    required() {
      return (value) => !!value || this.$t("Required:");
    },
    min() {
      return (v) => (v && v.length >= 8) || this.$t("Min 8 characters");
    },
  },

  methods: {
    ...mapActions("user", ["register"]),
    ...mapMutations("user", ["setAccessToken", "setRefreshToken"]),

    registerAction() {
      let userRegistrationInfo = {
        email: this.email,
        password: this.password,

        profile: {
          anonymous: this.anonymous,
          firstName: "",
          lastName: "",
          username: "",
        },

        therapyType: this.therapyType,
        userDisorder: this.userDisorder,
        clientPrefer: this.clientPrefer,

        promoCode: this?.promoCodeData?.code || "",

        insurance: this.insuranceData
      };

      if (this.anonymous) {
        userRegistrationInfo.profile.username = this.username;
      } else {
        userRegistrationInfo.profile.firstName = this.firstName;
        userRegistrationInfo.profile.lastName = this.lastName;
      }

      this.register(userRegistrationInfo).then((res) => {
        let datalayer = window?.dataLayer;
        if (datalayer) {
          datalayer.push({ event: "signup" });
        }
        console.log('herer in register', this.sponserType)
        this.setAccessToken(res.accessToken);
        this.setRefreshToken(res.refreshToken);
        if(this.sponserType === 'myCompany') {
          this.$router.push({name: 'VerifyEmail'})
        } else {
          this.$router.push({ name: "BaseContent" });
        }
        
        // this.$router.push({
        //   name: "TherapistSelection",
          // params: {
          //   passedEmail: userRegistrationInfo.email,
          //   passedUsername: userRegistrationInfo.profile.anonymous
          //     ? userRegistrationInfo.profile.username
          //     : userRegistrationInfo.profile.firstName,
          // },
        // });
      });

      // .catch((err) => {
      //   console.log("err: ", err);
      //   console.log("err.response: ", err.response);
      //   // if (err.response.status === 409) {
      //   //   this.$refs.registerViewRef.showResendVerificationButton = true;
      //   // }
      // });

      // if (this.$refs.registerForm.validate()) {
      //   this.$emit("onRegister");
      // }
    },
  },

  mounted() {
    if (!this.therapyType) {
      this.$router.replace({ name: "Services" });
    }
  },
};
</script>

<style scoped>
.reversed-label::v-deep .v-input__slot {
  flex-direction: row-reverse;
}

::v-deep.v-select .v-select__selection,
::v-deep.v-select .v-label {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.6);
}

::v-deep .v-text-field.v-text-field--solo .v-label {
  top: 10px !important;
}

.reversed-label::v-deep .v-input__slot {
  flex-direction: row-reverse;
  justify-content: start;
}
</style>
