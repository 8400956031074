<template>
  <v-dialog v-model="dialog" scrollable max-width="450px">
    <v-card
      style="
        box-shadow: 11px 10px 58px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
      "
    >
      <v-card-title class="pt-lg-12 pt-6 px-lg-10 px-5">
        <h2 class="mb-lg-2 mb-2 black--text headline font-weight-bold">
          {{ $t("CrisisManagement") }}
        </h2></v-card-title
      >
      <v-card-text class="pa-6 px-lg-10">
        <p>
          {{
            $t(
              "Many people with depression struggle with suicidal thoughts. If these thoughts become more specific or persistent, it's important to get help and TalkTime is not a substitute for crisis care."
            )
          }}
        </p>
        <p>
          {{
            $t(
              "You can continue with TalkTime if you choose, we also recommend finding a therapist in your area who can help you."
            )
          }}
        </p>
        <p class="mb-0">
          {{
            $t(
              "If you are having a medical or mental health emergency and are located in the UAE, please call 999 for Police, or 998 for Ambulance or contact to your local emergency department."
            )
          }}
        </p>
        <!--                </p>-->
      </v-card-text>

      <v-card-actions class="pb-6">
        <v-btn color="primary" class="mx-auto px-5" large @click="onAccept">{{
          $t("IUnderstand")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CrisisManagement",
  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    onAccept() {
      this.dialog = false;
      this.$emit("onAccept");
    },
  },
};
</script>
