<template>
  <div
    class="hp-body-bg not-logged-in d-flex flex-column"
    style="min-height: 100%"
  >
    <NoUserNavbar :show-back-button="getRoute === 'Login' ? '' : 'false'" />

    <v-container class="mt-lg-10 mt-3 my-lg-auto d-flex" justify-center>
      <!-- <div class="mx-auto my-auto"> -->

      <router-view />
      <!-- </div> -->
    </v-container>
    <NoUserFooter />
  </div>
</template>

<script>
import NoUserNavbar from "@/components/layout/NoUserNavbar";
import NoUserFooter from "@/components/layout/NoUserFooter";
import { mapGetters } from "vuex";

export default {
  name: "NoUserBase",
  components: { NoUserNavbar, NoUserFooter },

  computed: {
    ...mapGetters("user", ["isLoggedIn"]),
    getRoute() {
      return this.$route.name;
    },
  },

  mounted() {
    if (this.isLoggedIn) {
      this.$router.replace({ name: "Home" });
    }
  },
};
</script>

<style lang="scss">
.theme--light .hp-body-bg {
  background-position: top left, top right;
  /* background-attachment: fixed; */
  background-image: url("../../assets/background-gradient-left.png"),
    url("../../assets/background-gradient-right.png");
}
</style>
