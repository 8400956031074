<template>
  <v-menu offset-y left>
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        v-bind="attrs"
        width="36"
        height="36"
        v-on="on"
        :ripple="false"
        icon
        outlined
        plain
      >
        <v-icon size="20">mdi-account-cog</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item dense class="pb-0">
        <h5 class="font-size-13 mb-0 font-weight-bold">
          {{ $t("Language") }}:
        </h5>
      </v-list-item>
      <v-list-item>
        <v-chip-group column :value="lang">
          <v-chip small filter outlined value="en" @click="onChangeLang('en')"
            >English</v-chip
          >
          <v-chip small filter outlined value="ar" @click="onChangeLang('ar')">
            عربى</v-chip
          >
        </v-chip-group>
      </v-list-item>
      <!-- <v-divider></v-divider> -->
      <!-- <v-list-item dense class="mt-4 pb-0">
        <h5 class="font-size-13 mb-0 font-weight-bold">{{ $t("Theme") }}:</h5>
      </v-list-item> -->
      <!-- <v-list-item class="pt-0 pb-2">
        <v-switch
          :input-value="themeDark"
          @click="changeTheme"
          class="mt-0"
          :ripple="false"
          hide-details
        >
          <template v-slot:label>
            <span class="font-size-14">{{ $t("Dark mode") }}</span>
          </template>
        </v-switch>
      </v-list-item> -->
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "ChangeLanguage",
  computed: {
    ...mapGetters(["lang", "themeDark"]),
  },

  methods: {
    ...mapMutations(["changeLang", "changeThemeDark"]),

    changeTheme() {
      this.changeThemeDark(!this.themeDark);
    },

    onChangeLang(lang) {
      this.changeLang(lang);
    },
  },
};
</script>
