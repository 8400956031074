<template>
  <router-view :key="$route.path" />
</template>

<script>
import UserMixin from "@/mixins/UserMixin";
import { mapMutations } from "vuex";

export default {
  name: "Logged-In-Base",
  mixins: [UserMixin],

  methods: {
    ...mapMutations(["addNotification"]),
  },

  async created() {
    let paymentCompleted = this.$route.query?.["paid"];

    if (paymentCompleted) {
      let datalayer = window?.dataLayer;
      if (datalayer) {
        datalayer.push({ event: "paid" });
      }

      this.$router.replace({ query: null });
    }

    await this.getMe();

    if (this.isTherapist) {
      await this.getClients();
    }

    this.$socket.emit("user-connected");

    // window.ttt = () => {
    //   this.addNotification({
    //     message: this.$t("The call will be take place in a different room"),
    //     action: {
    //       color: "black",
    //       text: this.$t("Join the call"),
    //       cb: () => {
    //         window.open(
    //           "https://meet.jit.si/tk-" + data.data.eventId,
    //           "_blank"
    //         );
    //       },
    //     },
    //     outlined: false,
    //     icon: "mdi-alert",
    //     color: "error",
    //     iconColor: "white",
    //     closeClassList: "close-icon",
    //     closeColor: "black",
    //     timeout: 5 * 60 * 1000,
    //     classList: "centered-notification",
    //   });
    // };

    // window.tt2 = () => {
    //   this.addNotification({
    //     message: this.$t("The call will be take place in a different room"),
    //     action: {
    //       color: "warning",
    //       text: this.$t("Join the call"),
    //       cb: () => {
    //         window.open("https://meet.jit.si/tk-", "_blank");
    //       },
    //     },
    //     outlined: true,
    //     icon: "mdi-alert",
    //     color: "warning",
    //     closeColor: "warning",
    //     timeout: 5 * 60 * 1000,
    //   });
    // };

    this.$socket.on("notify:change-video-room", (data) => {
      this.addNotification({
        message: this.$t("The call will be take place in a different room"),
        action: {
          color: "black",
          text: this.$t("Join the call"),
          cb: () => {
            window.open(
              "https://meet.jit.si/tk-" + data.data.eventId,
              "_blank"
            );
          },
        },
        outlined: false,
        icon: "mdi-alert",
        color: "error",
        iconColor: "white",
        closeClassList: "close-icon",
        closeColor: "black",
        timeout: 5 * 60 * 1000,
        classList: "centered-notification",
      });
    });
  },
};
</script>

<style scoped lang="scss"></style>
