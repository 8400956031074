<template>
  <v-container class="container-spacing">
    <v-row class="align-stretch">
      <v-col cols="12" md="8" class="mx-auto d-flex flex-column">
        <h6 class="title font-weight-semibold text-center">
          {{
            $t(
              "What are your primary reasons for seeking our services for your child?"
            )
          }}
        </h6>
      </v-col>
      <v-col
        cols="12"
        md="8"
        class="py-0 mx-auto"
        v-for="(reason, i) in disordersListReasons"
        :key="i"
      >
        <v-btn
          elevation="0"
          :outlined="!reason.value"
          :color="!reason.value ? 'muted' : 'primary'"
          block
          :ripple="false"
          @click="selectReason(reason)"
          class="me-5 py-sm-2 px-2 mt-2 mb-2"
        >
          <div class="d-flex align-center">
            <v-icon v-if="!reason.value">mdi-circle-outline</v-icon>
            <v-icon v-else>mdi-check-circle</v-icon>

            <span class="ms-4">{{ $t(reason.variant) }}</span>
          </div>
        </v-btn>
      </v-col>
      <v-col cols="12" md="8" class="py-0 mx-auto">
        <v-btn
          elevation="0"
          :outlined="!otherTextArea"
          :color="!otherTextArea ? 'muted' : 'primary'"
          block
          :ripple="false"
          @click="showOtherText"
          class="me-5 py-sm-2 px-2 mt-2 mb-2"
        >
          <div class="d-flex align-center">
            <v-icon v-if="!otherTextArea">mdi-circle-outline</v-icon>
            <v-icon v-else>mdi-check-circle</v-icon>

            <span class="ms-4">{{ $t("Other concerns") }}</span>
          </div>
        </v-btn>
      </v-col>
      <v-col cols="12" md="8" class="mx-auto" v-if="otherTextArea">
        <v-textarea
          outlined
          :label="$t('Other concerns')"
          v-model="otherText"
        ></v-textarea>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn
          color="primary"
          class="px-5 py-1 mx-auto mt-8"
          :disabled="canContinue"
          @click="goNextStep"
        >
          {{ $t("Continue") }}
          <v-icon right>{{ $vuetify.rtl ? 'mdi-arrow-right mdi-rotate-180'  :  'mdi-arrow-right '}}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

// TODO BRING RIGHT LIST OF DISORDERS
export default {
  name: "TeenTherapyReasons",
  data: () => {
    return {
      loading: false,

      otherText: "",
      otherTextArea: false,

      disordersListReasons: [],

      selectedReasons: [],
    };
  },

  computed: {
    ...mapState("preRegister", ["therapyType", "userDisorder"]),

    ...mapGetters("disorder", ["disorders"]),

    canContinue() {
      return !(
        this.disordersListReasons.some((item) => {
          return item.value;
        }) || !!this.otherText?.trim()
      );
    },
  },

  watch: {
    disorders() {
      this.fillDisordersList();
    },
  },

  methods: {
    ...mapActions("disorder", {
      disorderGetAll: "getAll",
    }),

    ...mapMutations("preRegister", ["setUserDisorder"]),

    showOtherText() {
      this.otherTextArea = !this.otherTextArea;
    },

    fillDisordersList() {
      this.disordersListReasons = this.disorders
        .map((e) => {
          let _disorder = this.userDisorder.default.find(
            (d) => d.disorder === e._id
          );
          let checkVariant = !!_disorder;

          return e.variants.map((v) => {
            if (e.category !== "Teen") {
              return undefined;
            }

            let checked =
              checkVariant && _disorder.selectedVariants.includes(v);

            return {
              id: e._id,
              name: e.name,
              variant: v,
              value: checked,
            };
          });
        })
        .flat()
        .filter((e) => e);

      if (this.userDisorder.other) {
        this.otherTextArea = true;
        this.otherText = this.other;
      }
    },

    selectReason(reason) {
      reason.value = !reason.value;

      // reason.value
      //   ? (this.selectedServiceValue = reason.name)
      //   : (this.selectedServiceValue = "");
      //
      // this.selectedReasons = [];
      // this.reasons.map((item) => {
      //   if (item.value) {
      //     this.selectedReasons.push(item);
      //   }
      //   item.name === "Other concerns" && item.value
      //     ? (this.otherTextArea = true)
      //     : (this.otherTextArea = false);
      // });
    },

    goNextStep() {
      let defaultDisorders = Object.values(
        this.disordersListReasons
          .filter((e) => e.value)
          .reduce((a, r) => {
            if (!a[r.id]) {
              a[r.id] = { disorder: r.id, selectedVariants: [] };
            }
            a[r.id].selectedVariants.push(r.variant);
            return a;
          }, {})
      );

      this.setUserDisorder({
        default: defaultDisorders,
        other: this.otherTextArea ? this.otherText : "",
      });

      this.$router.push({
        name: "TeenTherapyDetails",
      });
    },
  },

  mounted() {
    if (!this.therapyType) {
      this.$router.replace({ name: "Services" });
    } else {
      this.loading = true;
      this.disorderGetAll()
        .then(() => {
          this.fillDisordersList();
          this.loading = false;
        })
        .catch((err) => {
          console.error("Error on Disorder get", err);
        });
    }
  },
};
</script>

<style scoped lang="scss">
::v-deep.v-btn {
  &.muted--text {
    .v-btn__content {
      color: #000;
    }
  }
  height: auto !important;
}
::v-deep .v-btn__content {
  white-space: normal;
  flex-wrap: wrap;
  flex: auto;
  justify-content: start;
  padding: 10px;
}

::v-deep.v-btn.v-btn--outlined:not(.v-btn--plain) {
  /* box-shadow: 0px 13px 21px rgba(52, 117, 178, 0.35); */
  border-radius: 15px !important;
}

::v-deep.v-btn--disabled {
  border-radius: 15px;
}
</style>
