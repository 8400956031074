<template>
  <v-container class="text-center container-spacing">
    <v-row>
      <v-col cols="12" sm="8" md="6" lg="4" xl="3" class="mx-auto">
        <img src="../../../assets/teenTherapyParent-3.svg" class="mb-10" />
        <p class="body-1">
          {{
            $t(
              "Teen therapy is only available for children between the age of 13-17. If your child is 18 years old or older, they may seek Individual Theraphy."
            )
          }}
        </p>
        <v-btn
          color="primary"
          block
          class="py-3 mt-10"
          :to="{ name: 'Services' }"
        >
          {{ $t("Go to individual therapy") }}
          <v-icon right>{{ $vuetify.rtl ? 'mdi-arrow-right mdi-rotate-180'  :  'mdi-arrow-right '}}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "TeenTherapyOlder",
};
</script>
