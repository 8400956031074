<template>
  <v-navigation-drawer
    :app="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? false : true"
    style="height: calc(100vh - 65px); position: fixed; top: 65px"
    hide-overlay
    :clipped="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? false : true"
    disable-resize-watcher
    color="lightGrey"
    class="d-flex flex-column flex-shrink-0"
    v-bind="appBarProps"
    :mini-variant="!drawer"
    @input="
      (e) => {
        $emit('update:drawer', e);
      }
    "
  >
    <div class="fill-height d-flex flex-column flex-shrink-0">
      <div class="mb-auto">
        <v-list-item
          class="py-2"
          v-if="!isTherapist && myTherapist"
          to="/my-therapist"
        >
          <v-list-item-avatar>
            <!-- <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img> -->
            <UserAvatar :user="myTherapist" :size="36" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <b class="font-weight-medium">{{
                getUserName(myTherapist)
              }}</b></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense rounded>
          <template v-for="item in items">
            <template v-if="item.children && item.show">
              <v-list-group :key="item.title" :value="true"  no-action>
                <template v-slot:activator>
                  <v-list-item-icon class="me-2">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </template>

                <template v-for="child in item.children">
                  <v-list-item
                    v-if="child.show"
                    :key="child.title"
                    color="primary"
                    :class="
                      !isTherapist && child.title === 'Patients' ? 'd-none' : ''
                    "
                    v-bind="child.props || null"
                    v-on="child.on || null"
                  >
                    <v-list-item-icon class="me-2">
                      <v-icon>{{ child.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{ child.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-group>
            </template>

            <template v-else>
              <v-list-item
                v-if="item.show"
                :key="item.title"
                color="primary"
                :class="
                  !isTherapist && item.title === 'Patients' ? 'd-none' : ''
                "
                v-bind="item.props || null"
                v-on="item.on || null"
              >
                <v-list-item-icon class="me-2">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </template>
        </v-list>
      </div>

      <div class="d-none d-md-block">
        <v-divider></v-divider>
        <v-btn
          icon
          class="ms-5 mt-3 pb-2"
          @click="toggleDrawer"
          :ripple="false"
          plain
        >
          <v-icon>mdi-chevron-double-left</v-icon>
        </v-btn>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import UserMixin from "@/mixins/UserMixin";
import UserAvatar from "@/components/UserAvatar";
import TherapistMixin from "@/mixins/TherapistMixin";

export default {
  props: {
    drawer: {
      required: true,
    },
  },
  components: {
    UserAvatar,
  },
  mixins: [UserMixin, TherapistMixin],

  computed: {
    currentTherapistChatRoomId() {
      if (this?.isTherapist) {
        return this.me?.rooms[0]?._id;
      }

      return (
        this.me?.rooms?.find((r) => r.users[0]._id === this.myTherapist?._id)
          ?._id || null
      );
    },

    items() {
      return [
        {
          title: this.$t("Chat"),
          icon: "mdi-chat",
          show:
            ((this.isClient || this.isTherapist) && !this.adminToken) ||
            (this.isTherapist && this.adminToken && this.isChiefTherapist),
          props: {
            to: this.currentTherapistChatRoomId
              ? {
                  name: "Room",
                  params: { id: this.currentTherapistChatRoomId },
                }
              : { name: "Chat" },
          },
        },
        {
          title: this.$t("Calendar"),
          icon: "mdi-store-clock",
          show: this.isClient || this.isTherapist,
          props: {
            to: { name: "Calendar" },
          },
        },
        {
          title: this.$t("Video"),
          icon: "mdi-message-video",
          show: this.isClient || this.isTherapist,
          props: {
            to: {
              name: "VideoCallBase",
            },
          },
        },
        {
          title: this.$t("Future-Self Journal"),
          icon: "mdi-note-text",
          show: this.isClient && this?.me?._id && !this.adminToken,
          props: {
            to: {
              name: "InteractiveJournal",
              params: { userId: this.me._id },
            },
          },
        },
        {
          title: this.$t("Clients"),
          icon: "mdi-account-multiple",
          show: this.isTherapist,
          props: {
            to: {
              name: "Patients",
            },
          },
        },
        {
          title: this.$t("Session Schedule"),
          icon: "mdi-store-clock",
          show: this.isAdmin,
          props: {
            to: { name: "SessionSchedule" },
          },
        },
        {
          title: this.$t("Client Log"),
          icon: "mdi-account-cog",
          show: this.isAdmin,
          props: {
            to: { name: "ClientLog" },
          },
        },
        {
          title: this.$t("Export"),
          icon: "mdi-text-box-search-outline",
          show: this.isAdmin,
          props: {
            to: {
              name: "DataExport",
            },
          },
        },
        {
          title: this.$t("Access Users"),
          icon: "mdi-account",
          show: this.isAdmin,
          props: {
            to: {
              name: "AccessUsers",
            },
          },
        },
        {
          title: this.$t("Users"),
          icon: "mdi-account-cog",
          show: this.isAdmin,
          props: {
            to: { name: "Users" },
          },
        },
        // {
        //   title: "Corporate program",
        //   icon: "mdi-domain",
        //   show: this.isAdmin,
        //
        // },

        // {
        //   title: "Companies",
        //   icon: "mdi-domain",
        //   show: this.isAdmin,
        //   props: {
        //     to: { name: "Companies" },
        //   },
        // },
        {
          title: "Corporate program",
          icon: "mdi-domain",
          show: this.isAdmin,
          children: [
            {
              title: "Companies",
              icon: "mdi-domain",
              show: this.isAdmin,
              props: {
                to: { name: "Companies" },
              },
            }, {
              title: "Corporate Plans",
              icon: "mdi-floor-plan",
              show: this.isAdmin,
              props: {
                to: { name: "CorporatePlan" },
              },
            },
            {
              title: "Client Database",
              icon: "mdi-database-cog-outline",
              show: this.isAdmin,
              props: {
                to: { name: "ClientDatabase" },
              },
            },

          ],
        },

        // {
        //   title: "Corporate Plans",
        //   icon: "mdi-floor-plan",
        //   show: this.isAdmin,
        //   props: {
        //     to: { name: "CorporatePlan" },
        //   },
        // },


        {
          title: this.$t("Therapists Panel"),
          icon: "mdi-list-box-outline",
          show: this.isAdmin,
          props: {
            to: { name: "TherapistsPanel" },
          },
        },
      ];
    },

    appBarProps() {
      let props = {
        right: this.$vuetify.rtl,
      };
      if (this.$vuetify.breakpoint.mobile) {
        props.value = this.drawer;
        props.absolute = true;
      } else {
        props.value = true;
        props["mini-variant"] = this.drawer;
      }
      return props;
    },
  },

  methods: {
    toggleDrawer() {
      this.$emit("update:drawer", !this.drawer);
    },
  },
};
</script>

<style scoped lang="scss">
.v-icon::v-deep {
  opacity: 0.75;
}
</style>
