<template>
  <v-container class="initial-assessment">
    <v-form v-model="valid">
      <v-row class="mb-4">
        <v-col cols="12">
          <h5 class="subtitle-1 font-weight-medium mb-4">
            {{ $t("WhatAreYourPrimaryReasonsForSeekingOurServices") }}
          </h5>
          <v-layout>
            <v-row>
              <template v-for="(dis, index) in disordersList">
                <v-col
                  :key="dis.id + '-' + index"
                  cols="12"
                  sm="6"
                  class="pb-1"
                >
                  <v-btn
                    elevation="0"
                    :outlined="!dis.value"
                    color="accent"
                    block
                    class="disorder-btn d-block py-2"
                    :ripple="false"
                    @click="dis.value = !dis.value"
                  >
                    {{ $t(dis.variant) }}
                  </v-btn>
                </v-col>
              </template>

              <v-col cols="12" md="6" class="pb-1">
                <v-btn
                  elevation="0"
                  :outlined="!otherDisorder.value"
                  color="accent"
                  block
                  class="d-block disorder-btn py-2"
                  :ripple="false"
                  @click="otherDisorder.value = !otherDisorder.value"
                >
                  {{ $t("Other concers") }}
                </v-btn>
              </v-col>
              <v-col v-if="otherDisorder.value" cols="12">
                <v-textarea
                  v-model="otherDisorder.text"
                  color="accent"
                  outlined
                  name="input-7-4"
                  :label="$t('SpecifyYourOtherConcers')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-layout>
        </v-col>
        <!-- <v-col cols="12">
          <h5 class="subtitle-1 font-weight-medium mb-3">{{ briefAssessment[0].q }}</h5>
          <v-layout>
            <v-btn v-for="a in questionAnswerData" :key="a.value" elevation="0"
                   :outlined="a.value !== briefAssessment[0].a" color="accent" class="me-2" :ripple="false"
                   @click="briefAssessment[0].a = a.value">{{ a.name }}
            </v-btn>
          </v-layout>
        </v-col> -->
      </v-row>
      <v-row class="mb-4">
        <v-col cols="12">
          <h5 class="subtitle-1 font-weight-medium mb-3">
            {{ $t("WouldYouPreferYourTherapistToBe") }}
          </h5>
          <v-btn
            v-for="a in genderData"
            :key="a.value"
            elevation="0"
            :outlined="
              a.value !== userRegistrationInfo.clientPrefer.therapistGender
            "
            color="accent"
            :block="$vuetify.breakpoint.xs ? true : false"
            class="me-5 py-sm-5 my-3 my-sm-0"
            :ripple="false"
            @click="userRegistrationInfo.clientPrefer.therapistGender = a.value"
          >
            {{ $t(a.name) }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mb-5">
        <v-col cols="12">
          <h5 class="subtitle-1 font-weight-medium mb-3">
            {{ $t("WhatIsYourPreferredLanguage") }}
          </h5>
          <v-layout>
            <v-btn
              v-for="a in langData"
              :key="a.value"
              elevation="0"
              :outlined="
                a.value !== userRegistrationInfo.clientPrefer.therapistLanguage
              "
              color="accent"
              class="me-5"
              :ripple="false"
              @click="
                userRegistrationInfo.clientPrefer.therapistLanguage = a.value
              "
            >
              {{ $t(a.name) }}
            </v-btn>
          </v-layout>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-btn color="primary" x-large block @click="prevStep">{{
            $t("Return")
          }}</v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            color="primary"
            x-large
            block
            :disabled="!briefAssessmentValid"
            @click="nextStep"
          >
            {{ $t("Complete") }}
            <i
              v-if="!$vuetify.rtl"
              class="mdi mdi-chevron-right mdi-24px ms-3"
            />
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ThirdStep",

  props: {
    userRegistrationInfo: {
      required: true,
    },
  },
  data: () => {
    return {
      disordersList: [],
      otherDisorder: {
        value: false,
        text: "",
      },

      valid: false,
      questionAnswerData: [
        {
          value: "no",
          name: "No",
        },
        {
          value: "yes",
          name: "Yes",
        },
      ],
      genderData: [
        {
          value: "female",
          name: "Female",
        },
        {
          value: "male",
          name: "Male",
        },
        {
          value: "noGenderPreference",
          name: "No gender preference",
        },
      ],
      langData: [
        {
          value: "arabic",
          name: "Arabic",
        },
        {
          value: "english",
          name: "English",
        },
      ],
    };
  },

  computed: {
    briefAssessmentValid() {
      return (
        (this.disordersList.some((item) => {
          return item.value;
        }) ||
          this.otherDisorder.text.trim()) &&
        // && this.briefAssessment.every((item, index) => {
        //   return index === 0 || item.a
        // })
        this.userRegistrationInfo.clientPrefer.therapistGender &&
        this.userRegistrationInfo.clientPrefer.therapistLanguage
      );
    },

    ...mapGetters("disorder", ["disorders"]),
  },

  watch: {
    disorders() {
      this.fillDisordersList();
    },
  },

  methods: {
    fillDisordersList() {
      console.log(
        "this.userRegistrationInfo.userDisorder: ",
        this.userRegistrationInfo.userDisorder
      );
      this.disordersList = this.disorders
        .map((e) => {
          let _disorder = this.userRegistrationInfo.userDisorder.default.find(
            (d) => d.disorder === e._id
          );
          let checkVariant = !!_disorder;

          return e.variants.map((v) => {
            let checked =
              checkVariant && _disorder.selectedVariants.includes(v);

            return {
              id: e._id,
              name: e.name,
              variant: v,
              value: checked,
            };
          });
        })
        .flat();

      if (this?.userRegistrationInfo?.userDisorder?.other) {
        this.otherDisorder.value = true;
        this.otherDisorder.text = this.userRegistrationInfo.userDisorder.other;
      }
    },

    nextStep() {
      // let briefAssessmentData = [];
      // let briefAssessmentCopy = JSON.parse(JSON.stringify(this.briefAssessment));
      //
      // briefAssessmentData.push(...briefAssessmentCopy.filter(e => e.a))

      let defaultDisorders = this.disordersList
        .filter((e) => e.value)
        .reduce((a, r) => {
          if (!a[r.id]) {
            a[r.id] = { disorder: r.id, selectedVariants: [] };
          }
          a[r.id].selectedVariants.push(r.variant);
          return a;
        }, {});

      defaultDisorders = Object.values(defaultDisorders);

      let disorder = {
        default: defaultDisorders,
        other: this.otherDisorder.value ? this.otherDisorder.text : "",
      };

      this.$emit("update:thirdStep", { disorder });

      this.$emit("update:stepperComplete", true);
    },

    prevStep() {
      this.$emit("update:step", 2);
    },
  },

  mounted() {
    this.fillDisordersList();
  },
};
</script>

<style scoped>
.v-btn.disorder-btn::v-deep {
  white-space: normal;
  height: 100% !important;
}
</style>
