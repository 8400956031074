<template>
  <div     class="hp-body-bg not-logged-in d-flex flex-column"
 style="min-height: 100%">
    <NoUserNavbar :show-back-button="false" />
    <v-container fluid class="my-auto">
      <v-row>
        <v-col cols="12" md="8" lg="6" xl="4" class="mx-auto">
          <v-card class="rounded-card-with-shadow card-spacing mx-auto">
            <!-- <v-toolbar color="primary" elevation="0" dark>
              <logoSvg />
            </v-toolbar> -->
            <v-card-text class="pa-6">
              <h2 class="mb-3 black--text title font-weight-bold">
                {{ $t("VerifyYourEmailAddress") }}
              </h2>
              <p class="subtitle-1">
                {{ $t("VerifyTitle") }},
                <span class="primary--text">{{ getUserName(me) }}</span>
              </p>
              <p class="subtitle-1">
                {{
                  $t("A verification link has been sent to your email address:")
                }}
              </p>

              <p class="primary--text">{{ me.email }}</p>

              <p>
                {{
                  $t(
                    "If you do not see the email in a few minutes, check your junk mail folder or spam folder."
                  )
                }}
              </p>
            </v-card-text>

            <div class="d-flex justify-center">
              <v-btn
                x-large
                color="primary"
                class="px-13 py-3 text-none mb-6"
                :disabled="!resendButtonShow"
                @click="resendEmail"
              >
                <template v-if="!resendButtonShow"> [{{ leftTime }}] </template>
                {{ $t("VerifyAction") }}
              </v-btn>
            </div>
            <div v-if="sponserType !== 'myCompany'" class="d-flex justify-center">
              <v-btn text color="primary" class="text-none mb-6" @click="onSkip">
                {{ $t("Verify later") }}
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <NoUserFooter />
  </div>

</template>

<script>
import NoUserNavbar from "@/components/layout/NoUserNavbar";
import NoUserFooter from "@/components/layout/NoUserFooter";
// import logoSvg from "./../../components/svg/Logo";
import { mapActions } from "vuex";
import userMixin from "@/mixins/UserMixin";
// import logoSvg from "@/components/svg/Logo";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "VerifyEmailAddress",
  components: { NoUserNavbar, NoUserFooter },

  data: () => {
    return {
      leftTime: 30,
      interval: null,
    };
  },

  mixins: [userMixin],

  computed: {
    resendButtonShow() {
      return this.leftTime <= 0;
    },
    ...mapState("preRegister", [
      "sponserType",
    ]),
  },

  methods: {
    ...mapActions("user", ["resendVerification"]),

    startTimeCount() {
      this.interval = setInterval(() => {
        this.leftTime--;

        if (this.leftTime <= 0) {
          this.stopTimeCount();
        }
      }, 1000);
    },

    stopTimeCount() {
      clearInterval(this.interval);
    },

    async resendEmail() {
      this.resendVerification({
        email: this.me.email,
      }).then(() => {
        this.leftTime = 180;
        this.startTimeCount();
      });
    },

    onSkip() {
      this.$router.replace({ name: "Calendar" });
    },
  },

  mounted() {
    let verificationExpires = moment(this.me.verificationExpires).isBefore(
      moment()
    );

    if (verificationExpires) {
      this.resendEmail();
      this.leftTime = 90;
    }

    if (this.leftTime) {
      this.startTimeCount();
    }
  },
};
</script>

<style scoped></style>
