<template>
  <v-container class="container-spacing">
    <v-row>
      <v-col cols="12" md="8" class="mx-auto d-flex flex-column">
        <h6 class="title font-weight-semibold mb-lg-2 text-center">
          {{ $t("Who will cover my therapy?") }}
        </h6>
        <v-row>
          <v-col
            v-for="(sponser, sponserIndex) in sponserList"
            :key="sponserIndex"
            class="pa-0"
            cols="12"
            md="12"
          >
            <v-btn
              elevation="0"
              block
              :ripple="false"
              @click="selectSponserOption(sponser)"
              :outlined="sponser.value !== selectedSponser"
              :color="sponser.value !== selectedSponser ? 'muted' : 'primary'"
              class="me-5 pa-2 mt-md-3 mt-2 mb-1"
            >
              <div
                class="d-flex justify-start text-start align-items-center w-100"
              >
                <businessmanSvg v-if="sponser.value === 'myCompany'" />
                <weddingSvg v-if="sponser.value === 'mySelf'" />
                <teenagerSvg v-if="sponser.value === 'myInsurance'" />
                <div class="d-flex w-100">
                  <div
                    class="mx-3 d-flex flex-column align-start justify-center"
                  >
                    <span
                      class="d-block font-weight-bold"
                      :class="
                        sponser.value !== selectedSponser
                          ? 'black--text text--lighten-5'
                          : 'white--text text--darken-1'
                      "
                    >
                      {{ $t(sponser.name) }}
                    </span>
                    <span
                      class="d-block"
                      :class="
                        !sponser.value
                          ? 'black--text text--lighten-5'
                          : 'white--text text--darken-1'
                      "
                      :style="
                        $vuetify.rtl ? 'font-size: 13px;' : 'font-size: 11px;'
                      "
                    >
                      {{ $t(sponser.subname) }}
                    </span>
                  </div>
                  <v-icon
                    class="ms-auto"
                    v-if="sponser.value !== selectedSponser"
                    >mdi-circle-outline</v-icon
                  >
                  <v-icon class="ms-auto" color="white" v-else
                    >mdi-check-circle</v-icon
                  >
                </div>
              </div>
            </v-btn>
            <div
              v-if="
                sponser.value === 'myInsurance' &&
                selectedSponser === 'myInsurance'
              "
            >
              <v-sheet
                color="#E5F5F6"
                class="rounded-bottom-15px pa-5 additional-info"
              >
                <InsuranceForm
                  @continueInsurance="continueInsurance"
                  :insuranceData.sync="insuranceData"
                />
              </v-sheet>
            </div>
            <v-sheet
              v-if="
                selectedSponser === 'myCompany' && sponser.value === 'myCompany'
              "
              color="#E5F5F6"
              class="rounded-bottom-15px additional-info"
            >
              <v-form ref="form">
                <div
                  class="d-flex pt-7 px-7 flex-md-row align-center flex-column"
                >
                  <v-text-field
                    outlined
                    solo
                    flat
                    dense
                    v-model="promoCode"
                    class="rounded-15px"
                    :label="$t('Enter code here')"
                    :error-messages="codeNotValidMessages"
                    :success="codeValid"
                    :readonly="codeValid"
                    :loading="loading"
                  />

                  <v-btn
                    :block="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                    color="primary"
                    :class="$vuetify.rtl ? 'mr-3' : 'ml-3'"
                    class="ml-3 align-self-start verify-button"
                    :loading="loading"
                    :disabled="codeValid"
                    @click="verifyCode"
                  >
                    {{ $t("Verify") }}
                  </v-btn>
                </div>
                <div
                  class="d-flex flex-wrap align-center font-size-12 justify-center pb-4"
                >
                  <p class="mb-0">{{ $t("Don't have a code?") }}</p>
                  <a
                    href="mailto:customercare@talktime.ae"
                    class="ms-1 text-decoration-none primary--text font-weight-bold"
                    >{{ $t("Contact us") }}</a
                  >
                </div>
                <div
                  class="d-flex flex-md-row flex-column mt-8 mt-md-0"
                  v-if="requiredEmail"
                >
                  <v-text-field
                    outlined
                    solo
                    flat
                    dense
                    class="rounded-15px"
                    :label="$t('Enter email here')"
                    :error-messages="emailNotValidMessages"
                    :success="emailValid"
                    :loading="loading"
                  />

                  <v-btn
                    :block="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                    color="primary"
                    class="ms-md-5 align-self-start verify-button py-3 px-7"
                    :loading="loading"
                    @click="verifyEmail"
                  >
                    {{ $t("Verify") }}
                  </v-btn>
                </div>
              </v-form>
            </v-sheet>
          </v-col>
        </v-row>
        <v-btn
          v-if="selectedSponser === 'mySelf'"
          color="primary"
          :disabled="selectedSponser !== 'mySelf'"
          class="px-5 py-1 mx-auto mt-5 mt-lg-7"
          @click="goNextStep"
        >
          {{ $t("Continue") }}
          <v-icon right>{{
            $vuetify.rtl ? "mdi-arrow-right" : "mdi-arrow-right"
          }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import businessmanSvg from "@/components/svg/Businessman";
import weddingSvg from "@/components/svg/Wedding";
import teenagerSvg from "@/components/svg/Teenager";
import InsuranceForm from "@/components/Admin/CorporateProgram/InsuranceForm.vue";

import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "Sponser",
  components: {
    businessmanSvg,
    teenagerSvg,
    InsuranceForm,
    weddingSvg,
  },
  data: () => {
    return {
      selectedServiceValue: "",
      loading: false,
      selectedSponser: "",
      promoCode: "",
      codeValid: false,
      codeNotValidMessages: [],
      requiredEmail: false,
      insuranceData: {
        insurancePolicyNumber: "",
        firstName: "",
        lastName: "",
        birthDate: "",
      },
      sponserList: [
        {
          name: "My Company (Employee Benefits)",
          sponserType: "Company",
          value: "myCompany",
        },
        {
          name: "Myself (out-of-pocket)",
          sponserType: "myself",
          value: "mySelf",
        },
        {
          name: "My Insurance",
          sponserType: "insurance",
          value: "myInsurance",
        },
      ],
    };
  },

  computed: {
    ...mapState("preRegister", ["therapyType"]),
    ...mapGetters("therapist", ["demoTherapist"]),
  },

  methods: {
    ...mapActions("corporateProgram", ["checkPromotionCode"]),
    ...mapActions("user", ["addPromoCodeToUser"]),
    ...mapActions("therapist", ["addTherapistToUserCompany"]),
    ...mapMutations("preRegister", [
      "setPromoCodeData",
      "setSponserType",
      "setInsuranceData",
      "setTherapyType",
    ]),

    async verifyCode() {
      this.loading = true;
      this.setSponserType(this.selectedSponser);
      try {
        let res = await this.checkPromotionCode({ code: this.promoCode });

        this.requiredEmail = res.requiredEmail;
        this.codeNotValidMessages = [];
        this.codeValid = true;

        this.setPromoCodeData(res);
        this.setTherapyType("Individual Therapy");
        if (!this.requiredEmail) {
          this.$router.push({ name: "PrimaryReasons" });
        }
      } catch (e) {
        console.error("verifyCode: ", e);
        // TODO: Sahak translate
        this.codeNotValidMessages = ["Code Not Valid"];
      }

      this.loading = false;

      // setTimeout(() => {
      //   this.loading = false;
      //   this.requiredEmail = true;
      //   this.codeValid = true;
      // }, 1000)
    },

    selectSponserOption(sponser) {
      this.setSponserType(this.selectedSponser);
      this.selectedSponser = sponser.value;
    },
    continueInsurance() {
      this.setSponserType(this.selectedSponser);
      this.setInsuranceData(this.insuranceData);
      this.goNextStep();
    },
    verifyEmail() {
      this.loading = true;

      setTimeout(() => {
        this.loading = false;
        this.requiredEmail = true;
        this.codeValid = true;

        this.setTherapyType("Individual Therapy");

        this.$router.push({
          name: "PrimaryReasons",
        });
      }, 1000);
    },
    async goNextStep() {
      this.setSponserType(this.selectedSponser);

      if (this.selectedSponser === "mySelf") {
        this.$router.push({ name: "Types" });
        return;
      }
      if (
        this.$route.name === "useCorporateCoupon" ||
        this.selectedSponser === "myCompany"
      ) {
        if (!this.demoTherapist) {
          await this.$router.replace("/");
        } else {
          await this.addPromoCodeToUser({ promoCode: this.promoCode });
          this.loading = true;
          this.addTherapistToUserCompany({
            id: this.demoTherapist._id,
          }).then(() => {
            this.$nextTick(async () => {
              this.loading = false;
              await this.getMe();
              await this.$router.replace({ name: "Calendar" });
            });
          });
        }
      } else {
        this.setTherapyType("Individual Therapy");

        await this.$router.push({
          name: "PrimaryReasons",
        });
      }
    },
  },

  mounted() {
    if (this.therapyType && this.services && this.services.length) {
      let hasService = this.services.find(
        (e) => e.therapyType === this.therapyType
      );
      hasService.value = true;
      this.selectedServiceValue = hasService.therapyType;
    }
  },
};
</script>

<style scoped>
::v-deep.v-btn {
  height: auto !important;
}
::v-deep .v-btn__content {
  white-space: normal;
  flex-wrap: wrap;
  flex: auto;
  justify-content: start;
  padding: 10px;
}

::v-deep.v-btn.v-btn--outlined:not(.v-btn--plain) {
  /* box-shadow: 0px 13px 21px rgba(52, 117, 178, 0.35); */
  border-radius: 15px !important;
}

::v-deep.v-btn--disabled {
  border-radius: 15px;
}
</style>
