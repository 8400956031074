<template>
  <!-- <v-container :class="[{ 'mx-auto my-auto': stepperComplete }]"> -->

  <v-row class="initial-assessment">
    <v-col
      cols="12"
      lg="9"
      xl="6"
      class="mx-auto"
      :class="[{ 'd-flex': stepperComplete }]"
    >
      <v-card
        class="rounded-lg"
        :class="[{ 'mx-auto': stepperComplete }]"
        :style="[stepperComplete ? { maxWidth: '550px' } : '']"
      >
        <v-toolbar color="primary" elevation="0" dark>
          <logoSvg />
          <router-link
            :to="{ name: 'Login' }"
            class="white--text ms-auto text-decoration-none"
            >{{ $t("AlreadyHaveAccount") }}</router-link
          >
        </v-toolbar>
        <v-card-title>
          <v-btn
            v-if="step === 1"
            @click="$router.go(-1)"
            plain
            :ripple="false"
            color="accent"
            small
            outlined
            icon
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn
            v-if="step === 2"
            @click="step = 1"
            plain
            :ripple="false"
            color="accent"
            small
            outlined
            icon
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn
            v-if="step === 3 && !stepperComplete"
            @click="step = 2"
            plain
            :ripple="false"
            color="accent"
            small
            outlined
            icon
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-btn
            v-if="stepperComplete"
            @click="
              step = 3;
              stepperComplete = false;
            "
            plain
            :ripple="false"
            color="accent"
            small
            outlined
            icon
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-12 px-10" v-if="!stepperComplete">
          <h1 class="text-center">{{ $t("BriefAssessment") }}</h1>
          <p
            v-if="step === 1"
            class="text-center mt-5 subtitle-2 grey--text text--darken-5"
          >
            {{ $t("BriefAssessmentTitle") }}
          </p>
          <p v-else-if="step === 2" class="text-center mt-5">
            {{ $t("RiskAssessment") }}
          </p>
          <p v-else class="text-center mt-5">
            {{ $t("AnswerAFewQuestionsOnHowYouFeelAndYourPreferences") }}
          </p>
          <v-stepper v-model="step" elevation="0">
            <v-stepper-header class="shadow-none elevation-0">
              <v-stepper-step
                color="primary"
                :complete="step > 1"
                step="1"
              ></v-stepper-step>

              <v-divider />

              <v-stepper-step
                color="primary"
                :complete="step > 2"
                step="2"
              ></v-stepper-step>

              <v-divider />

              <v-stepper-step color="primary" step="3"></v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <FirstStep
                  :userRegistrationInfo.sync="userRegistrationInfo"
                  :step.sync="step"
                  @update:firstStep="updateFirstStep"
                />
              </v-stepper-content>

              <v-stepper-content step="2">
                <SecondStep
                  :step.sync="step"
                  :userRegistrationInfo.sync="userRegistrationInfo"
                  @update:secondStep="updateSecondStep"
                />
              </v-stepper-content>

              <v-stepper-content step="3">
                <ThirdStep
                  :step.sync="step"
                  :userRegistrationInfo.sync="userRegistrationInfo"
                  :stepperComplete.sync="stepperComplete"
                  @update:thirdStep="updateThirdStep"
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <!-- <Register
          v-else
          ref="registerViewRef"
          :email.sync="userRegistrationInfo.email"
          :password.sync="userRegistrationInfo.password"
          @onRegister="registerAction"
        /> -->
      </v-card>
    </v-col>
  </v-row>

  <!-- </v-container> -->
</template>

<script>
import logoSvg from "./../../../components/svg/Logo";
// import Register from "./../Register";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep.vue";
import { mapActions } from "vuex";

export default {
  name: "InitialAssessment",
  components: {
    logoSvg,
    //  Register,
    FirstStep,
    SecondStep,
    ThirdStep,
  },

  data: () => {
    return {
      step: 1,
      stepperComplete: false,
      userRegistrationInfo: {
        email: "",
        password: "",
        profile: {
          anonymous: true,
          username: "",
          firstName: "",
          lastName: "",
          birthDate: null,
          gender: "male",
          location: {
            country: "",
          },
        },
        briefAssessment: [],
        userDisorder: {
          default: [],
          other: "",
        },
        clientPrefer: {
          therapistGender: "",
          therapistLanguage: "",
        },
      },
      firstStep: null,
      secondStep: null,
      // thirdStep: null,
    };
  },
  methods: {
    ...mapActions("user", ["register"]),
    ...mapActions("disorder", {
      disorderGetAll: "getAll",
    }),

    updateFirstStep(data) {
      this.firstStep = data;
    },
    updateSecondStep(data) {
      this.secondStep = data;
    },
    updateThirdStep({ disorder }) {
      this.userRegistrationInfo.userDisorder = disorder;
    },

    nextStep() {
      if (this.step >= 0 && this.step < 3) {
        console.log(this.step);
        this.step = this.step + 1;
      } else {
        this.stepperComplete = true;
      }
    },

    prevStep() {
      if (this.step > 1) {
        this.step = this.step - 1;
      }
    },

    registerAction() {
      this.userRegistrationInfo.briefAssessment.push(
        ...this.firstStep,
        ...this.secondStep
        //, ...this.thirdStep
      );

      this.register(this.userRegistrationInfo)
        .then(() => {
          let datalayer = window?.dataLayer;
          if (datalayer) {
            datalayer.push({ event: "signup" });
          }

          this.$router.push({
            name: "Login",
            params: {
              passedEmail: this.userRegistrationInfo.email,
              // passedUsername: this.userRegistrationInfo.profile.anonymous
              //   ? this.userRegistrationInfo.profile.username
              //   : this.userRegistrationInfo.profile.firstName,
            },
          });
        })
        .catch((err) => {
          if (err.response.status === 409) {
            this.$refs.registerViewRef.showResendVerificationButton = true;
          }
        });
    },
  },

  mounted() {
    this.disorderGetAll().catch((err) =>
      console.error("Error on Disorder get", err)
    );
  },
};
</script>

<style>
.min-w700px {
  min-width: 700px;
}

.mh-528px {
  max-height: 528px;
}

.overflow-y-auto {
  overflow-y: auto;
}
</style>
