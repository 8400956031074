<template>
  <v-card class="rounded-card-with-shadow card-spacing">
    <!-- <v-toolbar color="primary" elevation="0" dark>
      <logoSvg/>
    </v-toolbar> -->
    <v-card-text class="pa-6">
      <h2 class="mb-4 black--text title font-weight-bold">
        {{ $t("PasswordReset") }}
      </h2>
      <p class="mb-5 body-2 grey--text text--darken-5">
        {{ $t("PasswordResetTitle") }}
      </p>
      <v-form
        ref="resendForm"
        v-model="valid"
        lazy-validation
        @submit.prevent="forgotAction"
      >
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-text-field
              outlined
              dense
              v-model="email"
              :rules="[emailRule, required]"
              :label="$t('Email')"
              type="email"
              required
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn
              block
              color="primary"
              class="px-13 py-3 text-none"
              :disabled="!valid"
              @click="forgotAction"
            >
              {{ $t("ResetPassword") }}
            </v-btn>
          </v-col>
          <v-col cols="6" class="py-2">
            <router-link
              :to="{ name: 'Services' }"
              class="grey--text text--darken-5"
              >{{ $t("CreateAccount") }}</router-link
            >
          </v-col>
          <v-col cols="6" class="text-end py-2">
            <router-link
              :to="{ name: 'Login' }"
              class="grey--text text--darken-5"
              >{{ $t("SignIn") }}</router-link
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
// import logoSvg from "./../../components/svg/Logo";

export default {
  name: "ForgotPassword",
  // components: { logoSvg },
  props: ["passedEmail"],
  data: () => {
    return {
      valid: true,
      show: false,
      email: "",
    };
  },
  computed: {
    emailRule() {
      return (v) => /.+@.+\..+/.test(v) || this.$t("E-mail must be valid");
    },
    required() {
      return (value) => !!value || this.$t("Required:");
    },
  },
  mounted() {
    if (this.passedEmail) {
      this.email = this.passedEmail;
    }
  },
  methods: {
    ...mapActions("user", ["forgotPassword"]),

    forgotAction() {
      if (this.$refs.resendForm.validate()) {
        this.forgotPassword({
          email: this.email,
        }).then(() => {
          this.$router.push({
            name: "CheckEmail",
            params: { textMessage: "Reset password link sent" },
          });
        });
      }
    },
  },
};
</script>

<style scoped></style>
