<template>
  <v-container class="container-spacing">
    <v-row>
      <v-col cols="12" sm="6" class="mx-auto">
        <!-- <h6 class="body-2 font-weight-medium mt-10">

        </h6> -->
        <h6 class="subtitle-1 font-weight-semibold mb-3 mb-md-4">
          {{ $t("Are you the parent/legal guardian of the child?") }}
        </h6>
        <v-row>
          <v-col
            cols="auto"
            class="py-0"
            v-for="(a, i) in areYouParent"
            :key="i"
          >
            <v-btn
              elevation="0"
              :outlined="!a.checked"
              :color="!a.checked ? 'muted' : 'primary'"
              block
              :ripple="false"
              @click="toggleRadios(a, 'areYouParent')"
              class="me-5 py-md-4 py-3 mt-3 mb-3"
            >
              <div class="d-flex align-center">
                <div class="right-aligned-checkbox">
                  <v-icon v-if="!a.checked">mdi-circle-outline</v-icon>
                  <v-icon v-else>mdi-check-circle</v-icon>
                </div>

                <span>{{ $t(a.name) }}</span>
              </div>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col cols="12">
            <h6 class="subtitle-1 font-weight-semibold mb-3 mb-md-4">
              {{ $t("How old is your child?") }}
            </h6>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-btn
                  elevation="0"
                  :outlined="!reason.checked"
                  :color="!reason.checked ? 'muted' : 'primary'"
                  block
                  :ripple="false"
                  @click="toggleRadios(reason, 'howOldIsYourChild')"
                  v-for="(reason, i) in howOldIsYourChild"
                  :key="i"
                  class="me-5 py-sm-2 px-2 mt-3 mb-2 long-variant"
                >
                  <div class="d-flex align-center">
                    <v-icon v-if="!reason.checked">mdi-circle-outline</v-icon>
                    <v-icon v-else>mdi-check-circle</v-icon>

                    <span class="ms-4">{{ $t(reason.name) }}</span>
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn
                  color="primary"
                  class="px-5 py-1 mx-auto mt-8"
                  :disabled="canContinue"
                  @click="goNextStep"
                >
                  {{ $t("Continue") }}
                  <v-icon right>{{ $vuetify.rtl ? 'mdi-arrow-right mdi-rotate-180'  :  'mdi-arrow-right '}}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TeenTherapyDetails",
  data: () => {
    return {
      areYouParent: [
        { name: "Yes", checked: false },
        {
          name: "No",
          checked: false,
          notValid: true,
          // routeName: "TeenTherapyNotChildParent",
        },
      ],
      howOldIsYourChild: [
        {
          name: "Below 13",
          checked: false,
          notValid: true,
          routeName: "TeenTherapyBelow",
        },
        {
          name: "Between 13 - 17",
          checked: false,
          routeName: "TeenTherapyNotChildParent",
        },
        {
          name: "18 and above",
          checked: false,
          notValid: true,
          routeName: "TeenTherapyOlder",
        },
      ],
    };
  },

  computed: {
    ...mapState("preRegister", ["therapyType"]),

    selectedAreYouParent() {
      return this.areYouParent.find((e) => e.checked);
    },

    selectedHowOldIsYourChild() {
      return this.howOldIsYourChild.find((e) => e.checked);
    },

    canContinue() {
      return !(this.selectedAreYouParent && this.selectedHowOldIsYourChild);
    },
  },

  methods: {
    toggleRadios(item, objectName) {
      if (!item.checked) {
        this[objectName].map((e) => {
          if (e.name !== item.name) {
            e.checked = false;
          }
          item.checked = true;
        });
      }
    },

    goNextStep() {
      if (this.selectedAreYouParent?.notValid) {
        this.$router.push({ name: this.selectedHowOldIsYourChild.routeName });
      } else if (this.selectedHowOldIsYourChild?.notValid) {
        this.$router.push({ name: this.selectedHowOldIsYourChild.routeName });
      } else {
        this.$router.push({ name: "FitAssessmentQuestions" });
      }
    },
  },

  mounted() {
    if (!this.therapyType) {
      this.$router.replace({ name: "Services" });
    }
  },
};
</script>

<style scoped lang="scss">
::v-deep.v-btn:not(.back-btn) {
  &.long-variant {
    min-width: 100% !important;
    .v-btn__content {
      justify-content: start !important;
    }
  }
  min-width: 150px !important;
  height: auto !important;
  &.muted--text {
    .v-btn__content {
      color: #000;
    }
  }
  .v-btn__content {
    position: unset;
    justify-content: center !important;
  }
}
::v-deep .v-btn__content {
  white-space: normal;
  flex-wrap: wrap;
  flex: auto;
  justify-content: start;
  padding: 10px;
}

::v-deep.v-btn {
  /* box-shadow: 0px 13px 21px rgba(52, 117, 178, 0.35); */
  border-radius: 15px !important;
}

::v-deep.v-btn--disabled {
  border-radius: 15px;
}
.right-aligned-checkbox {
  position: absolute;
  right: 11px;
  top: 11px;
}
</style>
