<template>
  <v-dialog v-model="dialog" scrollable max-width="450px">
    <v-card
      style="
        box-shadow: 11px 10px 58px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
      "
    >
      <v-card-title class="pt-lg-12 pt-6 px-lg-10 px-5">
        <h2 class="mb-lg-2 mb-2 black--text headline font-weight-bold">
          {{ $t("CrisisManagement") }}
        </h2></v-card-title
      >
      <v-card-text class="mb-lg-7 mb-5 pb-0 px-lg-10 px-5 text-left">
        <!--                    <p style="white-space: pre-line;">-->
        <p>
          {{
            $t(
              "If you’re planning to hurt yourself, it's important for you to get help right away."
            )
          }}
        </p>
        <p>
          {{
            $t(
              "Thank you for taking the time to share openly and honestly. We understand it takes tremendous courage to reach out and ask for help, and you've taken a brave first step by visiting TalkTime."
            )
          }}
        </p>
        <p>
          {{
            $t(
              "If you are having a medical or mental health emergency and are located in the UAE, please call 999 for Police, or 998 for Ambulance or contact to your local emergency department."
            )
          }}
        </p>
        <p>
          {{
            $t(
              "Help is always available. We also recommend reaching out to a family member or friend to let them know that you are having these thoughts and ask for help and support."
            )
          }}
        </p>
        <p class="mb-0">
          {{
            $t(
              "We hope you will consider using some of these resources to get the help you deserve."
            )
          }}
        </p>
        <!--                    </p>-->
      </v-card-text>
      <v-card-actions class="pb-7">
        <v-btn
          color="primary"
          class="mx-auto px-5 mb-5"
          large
          @click="goBack"
          >{{ $t("IUnderstand") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// const LANDING_PAGE_URL = process.env.VUE_APP_LANDING_PAGE_URL;

export default {
  name: "TerminateAccess",

  data: () => {
    return {
      // landingPageUrl: LANDING_PAGE_URL
      dialog: false,
    };
  },
  methods: {
    goBack() {
      this.dialog = false;
      // this.$router.go(-1);
    },
  },
};
</script>
