<template>
  <!--  <div class="text-center">-->
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card class="d-flex flex-column">
      <v-icon class="ms-auto ma-3 black--text" @click="close">mdi-close</v-icon>
      <v-card-title class="justify-center">
        <img src="../../../assets/teenTherapyDialog.svg" class="mb-10" alt="" />
      </v-card-title>

      <v-card-text class="black--text px-14 text-center">
        {{
          $t(
            "Individual therapy is available for adults 18 years old and above. If you are below the age of 18, you may go to Teen Therapy."
          )
        }}
      </v-card-text>

      <v-card-actions class="px-14">
        <v-spacer></v-spacer>
        <v-btn
          @click="toTeenTherapy"
          color="primary"
          block
          class="px-5 py-4 mx-auto mb-4"
          style="box-shadow: 0 13px 21px rgba(52, 117, 178, 0.35)"
          >{{ $t("Go to Teen Therapy")
          }}<v-icon right>{{ $vuetify.rtl ? 'mdi-arrow-right mdi-rotate-180'  :  'mdi-arrow-right '}}</v-icon></v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--  </div>-->
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "TeenTherapyDialog",
  data: () => ({
    dialog: false,
  }),
  methods: {
    ...mapMutations("preRegister", ["setTherapyType"]),

    open() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    toTeenTherapy() {
      this.setTherapyType("Teen Therapy");

      this.$router.push({ name: "TeenTherapyReasons" });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep.v-btn {
  height: auto !important;
  border-radius: 15px;
}
.v-card__title {
  min-height: 268px;
}
</style>
