<template>
  <v-card class="rounded-card-with-shadow card-spacing">
    <!-- <v-toolbar color="primary" elevation="0" dark>
      <logoSvg />
    </v-toolbar> -->

    <v-card-text class="text-center pa-8">
      <div v-if="showResendVerificationButton">
        <h3 class="error--text my-4">{{ errorText }}</h3>
        <v-btn
          block
          color="error"
          :to="{
            name: 'VerifyEmail',
            params: { passedEmail: this.email, noStartCount: true },
          }"
        >
          Resend Verification
        </v-btn>
      </div>
      <div v-else-if="loading">
        <v-skeleton-loader
          class="mx-auto"
          width="460"
          type="sentences"
        ></v-skeleton-loader>
      </div>
      <div v-else>
        <v-icon size="50" color="success">mdi-check-circle</v-icon>
        <h3 class="success--text mt-4 font-weight-medium mb-10">
          {{ $t("Your account has been successfully verified") }}
        </h3>
        <v-btn
          :loading="loading"
          :to="{ name: 'Calendar' }"
          x-large
          block
          color="primary"
          class="px-13 mt-5 py-3 text-none"
          >{{ $t("Go To Dashboard") }}</v-btn
        >
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
// import logoSvg from "./../../components/svg/Logo";

export default {
  name: "Verify",
  // components: { logoSvg },

  data: () => {
    return {
      showResendVerificationButton: false,
      errorText: "",
      email: "",
      loading: false,
    };
  },

  methods: {
    ...mapActions("user", ["verify"]),
  },

  async mounted() {
    this.loading = true;
    await this.verify({ verification: this.$route.params.token })
      .then(() => {
        let datalayer = window?.dataLayer;
        if (datalayer) {
          datalayer.push({ event: "verified" });
        }
        // this.$router.replace({name: 'TherapistSelection'})
      })
      .catch((err) => {
        const data = err.response.data;
        if (data.verificationExpires) {
          this.showResendVerificationButton = true;
          this.errorText = data.message;
          this.email = data.email;
        }
      });
    this.loading = false;
  },
};
</script>

<style scoped></style>
