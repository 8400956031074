<template>
  <v-container class="container-spacing">
    <v-row>
      <v-col cols="12" md="8" class="mx-auto d-flex flex-column">
        <h6 class="title font-weight-semibold mb-lg-2 text-center">
          {{ $t("What kind of support are you looking for?") }}
        </h6>
        <v-btn
          elevation="0"
          :outlined="!s.value"
          :color="!s.value ? 'muted' : 'primary'"
          block
          :ripple="false"
          @click="selectService(s)"
          v-for="s in services"
          :key="s.name"
          class="me-5 pa-2 mt-md-3 mt-2 mb-1"
        >
          <div class="d-flex justify-start text-start align-items-center w-100">
            <businessmanSvg v-if="s.name === 'Individual therapy'" />
            <weddingSvg v-if="s.name === 'Couples Therapy'" />
            <teenagerSvg v-if="s.name === 'Teen Therapy'" />
            <coorporateSvg
              v-if="s.name === 'Check my Insurance or corporate Plan'"
            />
            <div class="d-flex w-100">
              <div class="mx-3 d-flex flex-column align-start justify-center">
                <span
                  class="d-block font-weight-bold"
                  :class="
                    !s.value
                      ? 'black--text text--lighten-5'
                      : 'white--text text--darken-1'
                  "
                >
                  {{ $t(s.name) }}
                </span>
                <span
                  class="d-block"
                  :class="
                    !s.value
                      ? 'black--text text--lighten-5'
                      : 'white--text text--darken-1'
                  "
                  :style="
                    $vuetify.rtl ? 'font-size: 13px;' : 'font-size: 11px;'
                  "
                >
                  {{ $t(s.subname) }}
                </span>
              </div>
              <v-icon class="ms-auto" v-if="!s.value"
                >mdi-circle-outline</v-icon
              >
              <v-icon class="ms-auto" color="white" v-else
                >mdi-check-circle</v-icon
              >
            </div>
          </div>
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!selectedServiceValue"
          class="px-5 py-1 mx-auto mt-5 mt-lg-7"
          @click="goNextStep"
        >
          {{ $t("Continue") }}
          <v-icon right>{{
            $vuetify.rtl ? "mdi-arrow-right " : "mdi-arrow-right "
          }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import businessmanSvg from "@/components/svg/Businessman";
import weddingSvg from "@/components/svg/Wedding";
import teenagerSvg from "@/components/svg/Teenager";

import { mapState, mapMutations } from "vuex";

export default {
  name: "Services",
  components: {
    businessmanSvg,
    teenagerSvg,
    weddingSvg,
  },
  data: () => {
    return {
      selectedServiceValue: "",

      services: [
        {
          name: "Individual therapy",
          therapyType: "Individual Therapy",
          subname: "Therapy for yourself on a one-on-one basis",
          value: false,
        },
        {
          name: "Couples Therapy",
          therapyType: "Couples Therapy",
          subname:
            "Therapy for you and your partner. Sessions are conducted individually, optionally you may invite your partner to join.",
          value: false,
        },
        {
          name: "Teen Therapy",
          therapyType: "Teen Therapy",
          subname: "Therapy for your child (13-17 years old)",
          value: false,
        },
      ],
    };
  },

  computed: {
    ...mapState("preRegister", ["therapyType"]),
  },

  methods: {
    ...mapMutations("preRegister", ["setTherapyType"]),

    selectService(service) {
      if (!service.value) {
        this.services.forEach((i) => {
          i.value = false;
        });
        service.value = true;

        service.value
          ? (this.selectedServiceValue = service.therapyType)
          : (this.selectedServiceValue = "");

        this.setTherapyType(this.selectedServiceValue);
      }
    },

    goNextStep() {
      switch (this.therapyType) {
        case "Individual Therapy":
          this.$router.push({
            name: "PrimaryReasons",
          });
          break;
        case "Couples Therapy":
          this.$router.push({
            name: "PrimaryReasons",
          });
          break;
        case "Teen Therapy":
          this.$router.push({
            name: "TeenTherapyReasons",
          });
          break;
        case "Corporate Empowerment Program":
          // window.location.replace("https://talktime.ae/corporate-empowerment/");
          this.$router.push({
            name: "AccessType",
            query: { next: "corporate-services" },
          });
          break;
      }
    },
  },

  mounted() {
    if (this.therapyType) {
      let hasService = this.services.find(
        (e) => e.therapyType === this.therapyType
      );
      hasService.value = true;
      this.selectedServiceValue = hasService.therapyType;
    }
  },
};
</script>

<style scoped>
::v-deep.v-btn {
  height: auto !important;
}
::v-deep .v-btn__content {
  white-space: normal;
  flex-wrap: wrap;
  flex: auto;
  justify-content: start;
  padding: 10px;
}

::v-deep.v-btn.v-btn--outlined:not(.v-btn--plain) {
  /* box-shadow: 0px 13px 21px rgba(52, 117, 178, 0.35); */
  border-radius: 15px !important;
}

::v-deep.v-btn--disabled {
  border-radius: 15px;
}
</style>
