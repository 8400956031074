<template>
  <v-card class="rounded-card-with-shadow card-spacing">
    <!-- <v-toolbar color="primary" elevation="0" dark>
      <logoSvg />
    </v-toolbar> -->
    <v-card-text class="pa-7">
      <h2 class="mb-4 black--text title font-weight-bold">
        {{ $t("SignIn") }}
      </h2>
      <p class="mb-5 body-2 grey--text text--darken-5">
        {{ $t("SignInSubTitle") }}
      </p>
      <v-form
        ref="loginForm"
        @submit.prevent="loginAction"
        v-model="valid"
        lazy-validation
      >
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-text-field
              outlined
              dense
              key="email-template"
              v-model="email"
              :rules="[required, emailRule]"
              type="email"
              :label="$t('Email')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-text-field
              outlined
              dense
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[required, min]"
              :type="show ? 'text' : 'password'"
              :label="$t('Password')"
              :hint="$t('At least 8 Characters:')"
              counter
              autocomplete="on"
              @click:append="show = !show"
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="py-0">
            <v-checkbox
              :label="$t('RememberMe')"
              color="primary"
              class="my-0"
              dense
            ></v-checkbox>
          </v-col>

          <v-col cols="12" class="text-end py-2">
            <v-btn
              type="submit"
              :loading="loading"
              block
              color="primary"
              class="px-13 py-3 text-none"
              :disabled="!valid || loading"
            >
              {{ $t("Login") }}
            </v-btn>
          </v-col>
          <v-col cols="6" class="py-2">
            <router-link
              :to="{ name: 'Services' }"
              class="grey--text text--darken-5"
              >{{ $t("CreateAccount") }}
            </router-link>
          </v-col>
          <v-col cols="6" class="text-end py-2">
            <router-link
              :to="{ name: 'ForgotPassword', params: { passedEmail: email } }"
              class="grey--text text--darken-5"
              >{{ $t("ForgotPassword") }}</router-link
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
// import logoSvg from "./../../components/svg/Logo";
export default {
  name: "Login",

  // components: { logoSvg },
  data: () => {
    return {
      valid: true,
      show: false,
      email: "",
      password: "",
      loading: false,
    };
  },

  computed: {
    emailRule() {
      return (v) => /.+@.+\..+/.test(v) || this.$t("E-mail must be valid");
    },
    required() {
      return (value) => !!value || this.$t("Required:");
    },
    min() {
      return (v) => (v && v.length >= 8) || this.$t("Min 8 characters");
    },

    // currentTherapistChatRoomId() {
    //   if (this.isTherapist) {
    //     return this.me?.rooms[0]?._id
    //   }

    //   return this.me?.rooms?.find(r => r.users[0]._id === this.myTherapist?._id)._id
    // },
  },

  methods: {
    ...mapActions("user", ["login"]),
    loginAction() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true;
        this.login({
          email: this.email,
          password: this.password,
        })
          .then((res) => {
            let datalayer = window?.dataLayer;
            if (datalayer) {
              datalayer.push({ event: "login" });
            }

            if (!res.verified && res.therapist) {
              this.$router.push({ name: "VerifyEmail" });
            } else {
              this.$router.push({ name: "BaseContent" });
            }
          })
          // .catch((e) => {
          //   if (e.response.status === 418) {
          //     this.$router.push({
          //       name: "VerifyEmail",
          //       params: { passedEmail: this.email, noStartCount: true },
          //     });
          //   }
          // })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
