<template>
  <div class="fill-height">
    <v-progress-circular
      v-if="globalLoading"
      class="position: absolute;"
      color="primary"
      style="
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1000000;
      "
      size="80"
      indeterminate
    />

    <template v-if="!loading && !globalLoading">
      <Navbar :drawer.sync="drawer" />

      <v-container
        fluid
        class="pa-0 fill-height"
        style="max-height: calc(100vh - 65px); overflow-y: auto"
      >
        <v-layout>
          <Sidebar :drawer.sync="drawer" />

          <v-container fluid class="px-2 pb-2 pt-3">
            <router-view :key="$route.path" />

            <!-- <v-select :items="[1,2,3,4,5]"></v-select> -->
          </v-container>
        </v-layout>
      </v-container>
    </template>

    <!-- <footer class="footer blue-bg white--text pt-8 mt-auto">
        <v-container>
          <Footer />
        </v-container>
      </footer> -->

    <ClientSurvey ref="ClientSurveyRef" />
    <!-- <Pricing /> -->
  </div>
</template>

<script>
import Navbar from "@/components/layout/Navbar.vue";
import Sidebar from "@/components/layout/Sidebar.vue";
// import Pricing from "@/views/loggedIn/Alone/Pricing";
import UserMixin from "@/mixins/UserMixin";
import TherapistMixin from "@/mixins/TherapistMixin";
// import Footer from '@/components/layout/Footer.vue';
import { mapActions } from "vuex";
import ClientSurvey from "@/components/Client/ClientSurvey";
import moment from "moment";

export default {
  name: "Logged-In-Base-Content",

  mixins: [UserMixin, TherapistMixin],
  components: {
    Navbar,
    Sidebar,
    ClientSurvey,
    // Pricing,
    // Footer,
    // logoSvg
  },

  data: () => {
    return {
      drawer: false,
      loading: false,
    };
  },

  methods: {
    ...mapActions("disorder", {
      disorderGetAll: "getAll",
    }),

    ...mapActions("clientSurvey", ["getPendingClientSurvey"]),

    routeCheck(to, from, next) {
      this.awaitPromiseCallback({
        key: "getMe",
        cb: () => {
          // console.log("this.isAdmin: ", this.isAdmin);
          // if(this.isAdmin) {
          // if(!to.path.includes('admin')) {
          //   this.$router.push({ name: "DataExport" })
          // }
          // } else

          // if (!this.$route.matched.some((record) => record.meta.requiresAdmin)) {
          //   if (!this.$route.meta.requiresAdmin) {
          //     this.$router.replace({name: "DataExport"})
          //   }
          // }
          // else

          if (this.isClient) {
            if (!this.myTherapist) {
              if(this.myInsurance && this.myInsurance.status === 'submitted') {
                this.$router.push({ name: "SubmittedByClient" });
              }
              else if(this.myInsurance && this.myInsurance.status === 'declined') {
                this.$router.push({ name: "NotConfirmedInsurance" });
              }
              else if(this.myInsurance && this.myInsurance.status === 'approved') {
                this.$router.push({ name: "ConfirmedInsurance" });
              } else {
                this.$router.push({ name: "TherapistSelection" });
              }
            } else if (this.me.firstTimeLogin) {
              this.$router.push({ name: "WelcomeBack" });
            }
          }

          // if (this.isClient && !this.myTherapist) {
          //   this.$router.replace({ name: "TherapistSelection" });
          // } else if (this.me.firstTimeLogin && this.isClient) {
          //   this.$router.replace({ name: "WelcomeBack" });
          // } else {
          //   // this.$router.replace({ name: "Calendar" })
          // }

          next();

          // if (this.isTherapist || this.myTherapist) {
          //   if(this.me.firstTimeLogin) {
          //     this.$router.replace({ name: "WelcomeBack" })
          //   }
          //   next()
          // } else {
          //   this.$router.replace({ name: "TherapistSelection" })
          // }
        },
      });
    },
  },

  beforeRouteUpdate(to, from, next) {
    this.routeCheck(to, from, next);
  },

  mounted() {
    this.loading = true;

    this.disorderGetAll().catch((err) =>
      console.error("Error on Disorder get", err)
    );


    this.awaitPromiseCallback({
      key: "getMe",
      cb: async () => {

        this.$nextTick(async () => {

          if (this.isClient) {
            this.getPendingClientSurvey().then((res) => {
              let minDate = moment();
              minDate.subtract(3, "hours");
              let pending = res.find((e) => {
                return minDate.isAfter(e.lastAction);
              });

              if (pending) {
                this.$refs.ClientSurveyRef.open(pending._id);
              }
            });
          }

          try {
            if (this.isAdmin) {
              if (
                !this.$route.matched.some((record) => record.meta.requiresAdmin)
              ) {
                if (!this.$route.meta.requiresAdmin) {
                  await this.$router.replace({ name: "DataExport" });
                }
              }
            } else if (this.isClient) {

              if (!this.myTherapist) {
                if(this.myInsurance && this.myInsurance.status === 'submitted') {
                  await this.$router.push({ name: "SubmittedByClient" });
                }
                else if(this.myInsurance && this.myInsurance.status === 'declined') {
                  await this.$router.push({ name: "NotConfirmedInsurance" });
                }
                else if(this.myInsurance && this.myInsurance.status === 'approved') {
                  await this.$router.push({ name: "ConfirmedInsurance" });
                } else {
                  await this.$router.push({ name: "TherapistSelection" });
                }
              } else if (this.me.firstTimeLogin) {
                await this.$router.push({ name: "WelcomeBack" });
              }
            }
            // else if (this.isClient && !this.myTherapist) {
            //   await this.$router.push({ name: "TherapistSelection" });
            // } else if (this.me.firstTimeLogin && this.isClient) {
            //   await this.$router.push({ name: "WelcomeBack" });
            // }
            // else {
            //   // await this.$router.push({ name: "Calendar" });
            // }
          } catch (e) {
            console.error("mounted BaseContent ERROR: ", e);
          } finally {
            this.loading = false;
          }
        });
      },
    });
  },
};
</script>

<style scoped lang="scss"></style>
