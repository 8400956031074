<template>
  <v-card class="rounded-card-with-shadow card-spacing">
    <!-- <v-toolbar color="primary" elevation="0" dark>
      <logoSvg />
    </v-toolbar> -->
    <v-card-text class="pa-6">
      <h2 class="mb-4 black--text title font-weight-bold">
        {{ $t("Please check your email") }}
      </h2>
      <p class="mb-5 body-2 grey--text text--darken-5">
        {{ $t(textMessage) }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CheckEmail",

  props: {
    textMessage: {
      default: () => "",
    },
  },
};
</script>

<style scoped></style>
