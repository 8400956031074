<template>
  <div class="bg-white d-flex align-center justify-center therapy-icon-wrapper">
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.8752 6.88406L17.0965 0.0749191C16.9069 -0.0262596 16.6797 -0.0248396 16.4916 0.0784691L4.06649 6.88762C3.86271 6.99944 3.73633 7.21316 3.73633 7.4457V16.3402C3.74378 23.6517 8.07565 30.2663 14.7747 33.1955L16.4994 33.947C16.6606 34.0173 16.8441 34.0177 17.0056 33.9478L18.949 33.1085C25.7723 30.2428 30.2114 23.5643 30.2121 16.1637V7.4457C30.2121 7.21068 30.0825 6.99447 29.8752 6.88406ZM28.9398 16.1637C28.9383 23.0542 24.8042 29.2715 18.4509 31.938L18.4466 31.9402L16.7543 32.6708L15.2838 32.0293C9.04803 29.3028 5.01544 23.1461 5.00834 16.3402V7.82236L16.8015 1.359L28.9398 7.82698V16.1637Z"
        fill="#3475B2"
      />
      <path
        d="M27.1536 8.84171L17.0957 3.35046C16.9465 3.26886 16.7677 3.27001 16.6196 3.35332L6.83994 8.84457C6.67955 8.93475 6.58008 9.10711 6.58008 9.29463V16.4676C6.58595 22.364 9.9955 27.6983 15.2683 30.0606L16.6257 30.6667C16.7526 30.7234 16.897 30.7237 17.0242 30.6673L18.5537 29.9905C23.9243 27.6794 27.4182 22.2935 27.4188 16.3253V9.29463C27.4188 9.1051 27.3168 8.93075 27.1536 8.84171ZM26.4173 16.3253C26.4162 21.8821 23.1623 26.8961 18.1617 29.0465L18.1584 29.0482L16.8263 29.6374L15.6689 29.1201C10.7608 26.9213 7.58685 21.9563 7.58126 16.4676V9.5984L16.8635 4.38601L26.4173 9.60212V16.3253Z"
        fill="#3475B2"
      />
      <path
        d="M12.1085 15.9276C11.8803 15.6603 11.4788 15.6287 11.2114 15.857C10.9441 16.0849 10.9125 16.4867 11.1408 16.7537L14.5013 20.689C14.7257 20.9517 15.1187 20.9876 15.3864 20.7696L23.213 14.4021C23.4856 14.1806 23.5268 13.7798 23.3049 13.5071C23.0834 13.2348 22.6826 13.1933 22.41 13.4152L15.0654 19.39L12.1085 15.9276Z"
        fill="#3475B2"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "corporate-code",
};
</script>

<!-- <style lang="scss">
    .theme--light {
        .fill-logo-white {
            fill: var(--v-primary-base);
        }
    }
    .theme--dark {
        .fill-logo-white {
            fill: white;
        }
    }
</style> -->
